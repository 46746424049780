import { Button, FlexContainer } from "@rizov/rizov-ui-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as WarningIcon } from "../assets/images/warning_circle.svg";
import { I18Namespace } from "../i18n";

type TProps = {
  message: string;
  callbackOnClose?: () => void;
}

const ErrorModalContent = ({message, callbackOnClose}: TProps) => {
  const [t] = useTranslation(I18Namespace.Main);

  return (
    <FlexContainer>
      <WarningIcon/>
      <p
        className="fs-20 mt-12 text-center">{message}</p>
      <Button className="mt-24 Mw-160" onClick={() => {
        if (typeof callbackOnClose === "function") {
          callbackOnClose();
        }
      }}>
        {t("General.ok")}
      </Button>
    </FlexContainer>
  );
}

export default ErrorModalContent;