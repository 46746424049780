import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { CartContext } from "../context/cart";
import { I18Namespace, Language } from "../i18n";
import { ProfileApiFactory } from "../services/api/v1";
import Dropdown, { DropdownItem } from "./Dropdown";
import styles from "./Header.module.scss";
import { ReactComponent as CartIcon } from "../assets/images/cart.svg";
import { ReactComponent as FacebookIcon } from "../assets/images/social/facebook_circle_filled.svg";
import { ReactComponent as EmailIcon } from "../assets/images/social/email_filled.svg";
import { Direction, FlexContainer } from "@rizov/rizov-ui-react";
import { AuthUserContext } from "../context/authUser";

const Header = () => {
  const authUser = useContext(AuthUserContext);
  const cart = useContext(CartContext);

  const [t, i18n] = useTranslation(I18Namespace.Main);
  const [isOpenBurgerNav, setIsOpenBurgerNav] = useState(false);

  const toggleBurgerNav = () => {
    setIsOpenBurgerNav(!isOpenBurgerNav);
  };

  const hideMobileNav = () => {
    if (isOpenBurgerNav) {
      setIsOpenBurgerNav(false);
    }
  };

  const logout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    ProfileApiFactory().logout().then(() => {
      authUser.setProfile(null);
    }).catch();
  }

  useEffect(() => {
    document.body.style.overflow = isOpenBurgerNav ? "hidden" : "auto";
  }, [isOpenBurgerNav]);

  return (
    <header className={`${styles.pageHeader} ${isOpenBurgerNav ? styles.open : ""}`} onClick={hideMobileNav}>

      <div className={styles.brand}>
        <Link to="/" className={styles.brandLink}>
          <img src={logo} alt="Logo Rizov Winery" className={styles.logo} />
        </Link>
      </div>

      <nav className={styles.navbar}>
        {authUser.profile && (
          <div className={styles.mobileProfile}>
            <div className={styles.userName}>{`${authUser.profile.first_name} ${authUser.profile.last_name}`}</div>
            <Link to={"/profile"} className={`${styles.userProfile} mt-12`}>
              {t("Menu.profileSettings")}
            </Link>
          </div>
        )}

        <ul className={`${styles.nav}`}>
          <li className={`${styles.navItem} ${styles.separator}`}>
            <Link to="/tastings" className={styles.navLink}>
              {t("Menu.tastings")}
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link to="/digital-storage" className={styles.navLink}>
              {t("Menu.digitalStorage")}
            </Link>
          </li>
          {authUser.profile ? (
            <>
              {(cart.eventId || cart.wineCellarId) && (
                <li className={`${styles.navItem} ${styles.navLeftAuto} ${styles.cart}`}>
                  <Link to={cart.eventId ? `/tasting/${cart.eventId}/cart` : `/digital-storage/wine-cellar/${cart.wineCellarId}/cart`} className={`${styles.navLink}`}>
                    <span className={`${styles.icon} ${!cart.isEmpty ? styles.filled : ""}`}>
                      <CartIcon />
                    </span>
                    <FlexContainer className={styles.text} direction={Direction.Row} spaceBetween>
                      <div className={styles.title}>
                        {t("Menu.myCart")}
                      </div>
                      <div className={styles.value}>
                        {cart.data ? t("Cart.navbar_count_products", { count: cart.data.total }) : ''}
                      </div>
                    </FlexContainer>
                  </Link>
                </li>
                )
              }
              <li className={`${styles.navItem} ${styles.navProfile} ${(!cart.eventId && !cart.wineCellarId) ? styles.navLeftAuto : ""}`} onClick={(e) => e.stopPropagation()}>
                <Dropdown title={`${authUser.profile.first_name} ${authUser.profile.last_name}`}>
                  <DropdownItem element={Link} to={"/profile"}>
                    {t("Menu.profileSettings")}
                  </DropdownItem>
                  <DropdownItem element={"button"} onClick={logout}>
                    {t("Menu.logout")}
                  </DropdownItem>
                </Dropdown>
              </li>
            </>
          ): (
            <>
              <li className={`${styles.navItem} ${styles.navLeftAuto}`}>
                <Link to="/" className={styles.navLink}>
                  {t("Menu.login")}
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/register/form" className={styles.navLink}>
                  {t("Menu.signup")}
                </Link>
              </li>    
            </>
          )}
          <li className={styles.navItem} onClick={(e) => e.stopPropagation()}>
            <Dropdown title={<><span className="desktop-only">{t(`LanguageShort.${i18n.language}`)}</span><span
              className="mobile-only">{t(`LanguageLong.${i18n.language}`)}</span></>}>
              {Object.values(Language).filter(v => v !== i18n.language).map((language, idx) => (
                <DropdownItem
                  key={language}
                  element={"button"}
                  onClick={() => {
                    i18n.changeLanguage(language);
                    localStorage.setItem("language", language as string);
                  }}
                >
                  <span className="desktop-only">{t(`LanguageShort.${language}`)}</span>
                  <span className="mobile-only">{t(`LanguageLong.${language}`)}</span>
                </DropdownItem>
              ))}
            </Dropdown>
          </li>
          {authUser.profile && (
            <li className={`${styles.navItem} mobile-only`}>
              <Link to={"/#"} onClick={logout} className={styles.navLink}>
                {t("Menu.logout")}
              </Link>
            </li>
          )}
        </ul>

        <div className={`${styles.socialNetworks} mobile-only`}>
          <p className={styles.title}>Get in contact with us:</p>

          <FlexContainer direction={Direction.Row} className={styles.navSocial} gap={24}>
            <FlexContainer className={styles.socialItem}>
              <a href="https://www.facebook.com/rizov.winery/" target="_blank" rel="nofollow noreferrer" className={styles.socialLink}>
                <FacebookIcon />
              </a>
            </FlexContainer>
            <FlexContainer className={styles.socialItem}>
              <a href="mailto: risest@mail.ru" target="_blank" rel="nofollow noreferrer" className={styles.socialLink}>
                <EmailIcon />
              </a>
            </FlexContainer>
          </FlexContainer>
        </div>
      </nav>

      <div className={`${styles.hamburgerLines} ${isOpenBurgerNav ? styles.open : ""}`} onClick={toggleBurgerNav}>
        <span className={`${styles.line} ${styles.line1}`}></span>
        <span className={`${styles.line} ${styles.line2}`}></span>
        <span className={`${styles.line} ${styles.line3}`}></span>
      </div>

    </header>
  );
};

export default Header;