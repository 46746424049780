import { Direction, FlexContainer, HorizontalAlign, Tab, Tabs, VerticalAlign } from "@rizov/rizov-ui-react";
import { AxiosError } from "axios";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FadeIn from "../components/FadeIn";
import InfiniteScroll from "../components/InfiniteScroll";
import { TrackingContext } from "../context/tracking";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { I18Namespace } from "../i18n";
import styles from "../pages/WineCellar.module.scss";
import { CollectionExceptionList, CollectionHistoryEntryTypeEnum, CollectionHistoryList, CollectionMeasurements, MeasurementCollectionHistoryEntry, OwnerCollectionHistoryEntry, StorageApiFactory, StorageCollectionHistoryEntry, TrackingBoundaries } from "../services/api/v1";


type TGroupBy = 'day' | 'month';

const checkTrackingTemperatureWarning = (trackingBoundaries?: TrackingBoundaries, min_value?: number | null, max_value?: number | null) => {
  return (!min_value || (trackingBoundaries && (trackingBoundaries.temperature_min <= min_value && min_value <= trackingBoundaries.temperature_max)))
    && (!max_value || (trackingBoundaries && (trackingBoundaries.temperature_min <= max_value && max_value <= trackingBoundaries.temperature_max)));
};

const checkTrackingHumidityWarning = (trackingBoundaries?: TrackingBoundaries, min_value?: number | null, max_value?: number | null) => {
  return (!min_value || (trackingBoundaries && (trackingBoundaries.humidity_min <= min_value && min_value <= trackingBoundaries.humidity_max)))
    && (!max_value || (trackingBoundaries && (trackingBoundaries.humidity_min <= max_value && max_value <= trackingBoundaries.humidity_max)));
};

const checkTrackingIlluminationWarning = (trackingBoundaries?: TrackingBoundaries, min_value?: number | null, max_value?: number | null) => {
  return (!min_value || (trackingBoundaries && (trackingBoundaries.illumination_min <= min_value && min_value <= trackingBoundaries.illumination_max)))
    && (!max_value || (trackingBoundaries && (trackingBoundaries.illumination_min <= max_value && max_value <= trackingBoundaries.illumination_max)));
};

const isOwnerCollectionHistoryEntry = (value: any): value is OwnerCollectionHistoryEntry => {
  return value.type === CollectionHistoryEntryTypeEnum.Owner
};

const isStorageCollectionHistoryEntry = (value: any): value is StorageCollectionHistoryEntry => {
  return value.type === CollectionHistoryEntryTypeEnum.Storage
}

const isMeasurementCollectionHistoryEntry = (value: any): value is MeasurementCollectionHistoryEntry => {
  return value.type === CollectionHistoryEntryTypeEnum.Measurement;
}

const renderHistoryValue = (min: number | null, max: number | null) => {
  if (min === null) {
    return max;
  } else if (max === null) {
    return min;
  }
  return min === max ? min : `${min}-${max}`;
}

const enum ETab {
  History = 'history',
  Exceptions = 'exceptions',
}

const WineCellarConditions = () => {
  const [t] = useTranslation(I18Namespace.Main);
  const { collectionId } = useParams();
  const tracking = useContext(TrackingContext);

  const _navigate = useNavigate();
  const navigate = useRef(_navigate);
  const location = useLocation();
  const [history, setHistory] = useState<CollectionHistoryList>();
  const [offset, setOffset] = useState<number>(0);
  const [groupBy, setGroupBy] = useStateWithSearchParams<TGroupBy | undefined>('group');
  const [loadedItems, setLoadedItems] = useState<number>(10);
  const [activeTab, setActiveTab] = useStateWithSearchParams<ETab>('tab', ETab.History);
  const [exception, setException] = useState<CollectionExceptionList>();
  const [measurements, setMeasurements] = useState<CollectionMeasurements>();

  const onChangeGroupBy = (type: TGroupBy) => {
    setOffset(0);
    setGroupBy(type);
  }

  const updateHistoryOffset = () => {
    const nextOffset = offset + loadedItems;
    if (history && nextOffset < history.total) {
      setOffset(prevValue => prevValue + loadedItems);
    }
  };

  const updateExceptionOffset = () => {
    const nextOffset = offset + loadedItems;
    if (exception && nextOffset < exception.total) {
      setOffset(prevValue => prevValue + loadedItems);
    }
  };

  useEffect(() => {
    if (collectionId) {
      StorageApiFactory().getCollectionMeasurements(parseInt(collectionId)).then(response => {
        setMeasurements(response.data);
      }).catch(error => {
        if (error instanceof AxiosError) {
          navigate.current(location.pathname.split('/').slice(0, -2).join('/'));
        }
        else {
          navigate.current("/digital-storage");
          alert(t("ValidationError.syntax_error"));
        }
      });
    }
  }, [collectionId, location.pathname, t]);

  useEffect(() => {
    if (collectionId) {
      if (activeTab === ETab.History) {
        StorageApiFactory().getCollectionHistory(parseInt(collectionId), offset, undefined, groupBy).then(response => {
          setHistory((result) => {
            if (result === undefined || offset === 0) {
              return response.data;
            }
            return {
              ...result,
              items: [...result.items, ...response.data.items]
            };
          });
          setLoadedItems(response.data.items.length);
        }).catch(error => {
          if (error instanceof AxiosError) {
            navigate.current(location.pathname.split('/').slice(0, -2).join('/'));
          }
          else {
            navigate.current("/digital-storage");
            alert(t("ValidationError.syntax_error"));
          }
        });
      } else if (activeTab === ETab.Exceptions) {
        StorageApiFactory().getCollectionExceptions(parseInt(collectionId), offset, undefined, groupBy).then(response => {
          setException((result) => {
            if (result === undefined || offset === 0) {
              return response.data;
            }
            return {
              ...result,
              items: [...result.items, ...response.data.items]
            };
          });
          setLoadedItems(response.data.items.length);
        }).catch(error => {
          if (error instanceof AxiosError) {
            navigate.current(location.pathname.split('/').slice(0, -2).join('/'));
          }
          else {
            navigate.current("/digital-storage");
            alert(t("ValidationError.syntax_error"));
          }
        });
      }
    }
  }, [collectionId, offset, groupBy, activeTab, location.pathname, t]);

  const onChangeTab = (selectedTab: ETab) => {
    if (selectedTab === activeTab) {
      return;
    }
    setActiveTab(selectedTab);
    setLoadedItems(0);
    setGroupBy(undefined);
    setOffset(0);
  };

  const renderConditionTemperatureSensor = useMemo(() => {
    if (measurements && measurements.temperature && tracking.boundaries?.item) {
      return (
        <FadeIn>
          <FlexContainer className={`${styles.sensor} ${!measurements.humidity && !measurements.illumination ? styles.lastSensor : ""}`} spaceBetween>
            <h4 className={styles.title}>{t("General.temperature")}</h4>
            <FlexContainer>
              <span className={`${styles.indicator} ${!checkTrackingTemperatureWarning(tracking.boundaries.item, measurements.temperature) ? styles.warning : ""}`}>{measurements.temperature}°</span>
              <span className={styles.normals}>{t("General.normal")}: {`${tracking.boundaries?.item.temperature_min}-${tracking.boundaries?.item.temperature_max}°`}</span>
              <span className={styles.lastUpdate}>
                {t("General.last_update", { date: moment(measurements.datetime).format("DD.MM.YY HH:mm") })}
              </span>
            </FlexContainer>
          </FlexContainer>
        </FadeIn>
      );
    }
    return <></>;
  }, [measurements, tracking.boundaries?.item, t]);

  const renderConditionHumiditySensor = useMemo(() => {
    if (measurements && measurements.humidity && tracking.boundaries?.item) {
      return (
        <FadeIn>
          <FlexContainer className={`${styles.sensor} ${!measurements.temperature && !measurements.illumination ? styles.lastSensor : ""}`} spaceBetween>
            <h4 className={styles.title}>{t("General.humidity")}</h4>
            <FlexContainer>
              <span className={`${styles.indicator} ${!checkTrackingHumidityWarning(tracking.boundaries.item, measurements.humidity) ? styles.warning : ''}`}>{measurements.humidity}%</span>
              <span className={styles.normals}>{t("General.normal")}: {`${tracking.boundaries.item.humidity_min}-${tracking.boundaries.item.humidity_max}%`}</span>
              <span className={styles.lastUpdate}>{t("General.last_update", { date: moment(measurements.datetime).format('DD.MM.YY HH:mm') })}</span>
            </FlexContainer>
          </FlexContainer>
        </FadeIn>
      );
    }
    return <></>;
  }, [measurements, tracking.boundaries?.item, t]);

  const renderConditionIlluminationSensor = useMemo(() => {
    if (measurements && measurements.illumination && tracking.boundaries?.item) {
      return (
        <FadeIn>
          <FlexContainer className={`${styles.sensor} ${!measurements.humidity && !measurements.temperature ? styles.lastSensor : ""}`} spaceBetween>
            <h4 className={styles.title}>{t("General.luminosity")}</h4>
            <FlexContainer>
              <span className={`${styles.indicator} ${!checkTrackingIlluminationWarning(tracking.boundaries.item, measurements.illumination) ? styles.warning : ''}`}>{measurements.illumination} lm</span>
              <span className={styles.normals}>{t("General.normal")}: {`${tracking.boundaries.item.illumination_min}-${tracking.boundaries.item.illumination_max}`} lm</span>
              <span className={styles.lastUpdate}>{t("General.last_update", { date: moment(measurements.datetime).format('DD.MM.YY HH:mm') })}</span>
            </FlexContainer>
          </FlexContainer>
        </FadeIn>
      );
    }
    return <></>;
  }, [measurements, tracking.boundaries?.item, t]);

  const renderInconsistency = useMemo(() => {
    if (measurements && measurements.inconsistency !== 0) {
      return (
        <FadeIn>
          <FlexContainer className={`${styles.warningBox} mt-24 mt-12--992`}>
            {t("DigitalStorage.oneBottleLeftStorage")}
          </FlexContainer>
        </FadeIn>
      );
    }
    return <></>;
  }, [measurements, t]);

  const renderBatchLeftStorage = useMemo(() => {
    if (measurements && !measurements.temperature && !measurements.humidity && !measurements.illumination) {
      return (
        <FadeIn>
          <FlexContainer className={`${styles.dangerBox} mt-24 mt-12--992`}>
            {t("DigitalStorage.batchLeftStorage")}
          </FlexContainer>
        </FadeIn>
      );
    }
    return <></>;
  }, [measurements, t]);

  return (
    <FlexContainer direction={Direction.Row} className={`centered-content mt-60 ${styles.conditions}`} verticalAlign={VerticalAlign.Top}>
      <FlexContainer className={styles.currentConditions}>
        <div className={styles.sectionTitle}>{t("General.currentConditions")}</div>
        {renderInconsistency}
        {renderBatchLeftStorage}
        <FlexContainer direction={Direction.Row} wrap className={`${styles.sensorContainer} mt-24 mt-12--992`}>
          {renderConditionTemperatureSensor}
          {renderConditionHumiditySensor}
          {renderConditionIlluminationSensor}
        </FlexContainer>
      </FlexContainer >
      <FlexContainer>
        <Tabs active={activeTab}>
          <Tab title={t("General.history")} onClick={onChangeTab} id={ETab.History}>
            <FlexContainer className={styles.historyFilters}
              direction={Direction.Row} gap={40} horizontalAlign={HorizontalAlign.Left}>
              <Link to={"/#"} onClick={(e) => {
                e.preventDefault();
                onChangeGroupBy("day");
              }} className={`${styles.item} ${groupBy === 'day' ? styles.active : ""}`}>
                {t("General.groupByDate")}
              </Link>
              <Link to={"/#"} onClick={(e) => {
                e.preventDefault();
                onChangeGroupBy("month");
              }} className={`${styles.item} ${groupBy === 'month' ? styles.active : ""}`}>
                {t("General.groupByMonth")}
              </Link>
            </FlexContainer>


            {history && (
              <FadeIn>
                <div className={`${styles.historyList} mt-24`}>
                  <InfiniteScroll
                    dataLength={history.items.length}
                    hasMore={history.items.length < history.total}
                    loader={
                      <FlexContainer>
                        {t("General.loading")}
                      </FlexContainer>
                    }
                    next={updateHistoryOffset}
                  >
                    {history.items.map((item, index) => {
                      if (isOwnerCollectionHistoryEntry(item)) {
                        return (
                          <div className={styles.row}>
                            <div
                              className={styles.date}>{moment(item.date).format("DD MMM YYYY - HH:mm")}</div>
                            <div className={styles.message}>
                              <div
                                className={styles.item}>{t("DigitalStorage.History.purchasedBy", { name: item.owner })}</div>
                            </div>
                          </div>
                        );
                      } else if (isStorageCollectionHistoryEntry(item)) {
                        return (
                          <div className={styles.row}>
                            <div
                              className={styles.date}>{moment(item.date).format("DD MMM YYYY - HH:mm")}</div>
                            <div className={styles.message}>
                              <div
                                className={styles.item}>{t("DigitalStorage.History.placedInStorage", { storage: item.storage })}</div>
                            </div>
                          </div>
                        );
                      } else if (isMeasurementCollectionHistoryEntry(item)) {
                        item.illumination_max = null;
                        return (
                          <div className={styles.row}>
                            <div
                              className={styles.date}>{moment(item.date).format("DD MMM YYYY")}</div>
                            <div className={styles.indicators}>
                              {(item.temperature_min !== null || item.temperature_max !== null) && (
                                <div
                                  className={`${styles.item} ${!checkTrackingTemperatureWarning(tracking.boundaries?.item, item.temperature_min, item.temperature_max) ? styles.warning : ""}`}>{t("General.temp")}: {renderHistoryValue(item.temperature_min, item.temperature_max)}°C</div>
                              )}
                              {(item.humidity_min !== null || item.humidity_max !== null) && (
                                <div
                                  className={`${styles.item} ${!checkTrackingHumidityWarning(tracking.boundaries?.item, item.humidity_min, item.humidity_max) ? styles.warning : ""}`}>{t("General.humidity")}: {renderHistoryValue(item.humidity_min, item.humidity_max)}%</div>
                              )}
                              {(item.illumination_min !== null || item.illumination_max !== null) && (
                                <div
                                  className={`${styles.item} ${!checkTrackingIlluminationWarning(tracking.boundaries?.item, item.illumination_min, item.illumination_max) ? styles.warning : ""}`}>{t("General.lum")}: {renderHistoryValue(item.illumination_min, item.illumination_max)} lm</div>
                              )}
                            </div>
                          </div>
                        );
                      }
                      return <></>;
                    })
                    }
                  </InfiniteScroll >
                </div >
              </FadeIn >
            )}


          </Tab >
          <Tab title={t("General.exceptions")} onClick={onChangeTab} id={ETab.Exceptions}>
            <FlexContainer className={styles.historyFilters}
              direction={Direction.Row} gap={40} horizontalAlign={HorizontalAlign.Left}>
              <Link to={"/#"} onClick={(e) => {
                e.preventDefault();
                onChangeGroupBy("day");
              }} className={`${styles.item} ${groupBy === 'day' ? styles.active : ""}`}>
                {t("General.groupByDate")}
              </Link>
              <Link to={"/#"} onClick={(e) => {
                e.preventDefault();
                onChangeGroupBy("month");
              }} className={`${styles.item} ${groupBy === 'month' ? styles.active : ""}`}>
                {t("General.groupByMonth")}
              </Link>
            </FlexContainer>

            {exception && (
              <FadeIn>
                <div className={`${styles.historyList} mt-24`}>
                  <InfiniteScroll
                    dataLength={exception.items.length}
                    hasMore={exception.items.length < exception.total}
                    loader={
                      <FlexContainer>
                        {t("General.loading")}
                      </FlexContainer>
                    }
                    next={updateExceptionOffset}
                  >
                    {exception.items.map((item, index) => (
                      <div className={styles.row}>
                        <div className={styles.date}>{moment(item.date).format('DD MMM YYYY')}</div>
                        <div className={styles.exception}>
                          <div className={styles.item}>{
                            item.value_min === item.value_max ?
                              t(`DigitalStorage.Exceptions.${item.property.toLowerCase()}_fixed`, { value: item.value_min }) :
                              t(`DigitalStorage.Exceptions.${item.property.toLowerCase()}_min_max`, { min: item.value_min, max: item.value_max })
                          }</div>
                          <div className={styles.time} title={moment.utc(item.duration * 1000).format("H[h] mm[min] ss[s]")}>
                            {moment.utc(item.duration * 1000).format("H[h] mm[min]")}
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </FadeIn>
            )}
          </Tab>
        </Tabs >
      </FlexContainer >
    </FlexContainer >
  )
}

export default WineCellarConditions;
