import { Button, FlexContainer } from "@rizov/rizov-ui-react";
import React from "react";
import { ReactComponent as LikeIcon } from "../assets/images/like_ok.svg";

type TProps = {
  message: string | React.ReactNode;
  onClick?: () => void;
}
const SuccessModalContent = ({message, onClick}: TProps) => {

  return (
    <FlexContainer>
      <LikeIcon/>
      <div className="fs-20 mt-12 text-center">
        {message}
      </div>
      <Button className="mt-24 Mw-160" onClick={onClick}>
        OK
      </Button>
    </FlexContainer>
  );
};

export default SuccessModalContent;