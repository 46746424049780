import { createContext } from "react";
import { EventCart } from "../../services/api/v1";

const emptyFunction = () => {
  return;
};

type Props = {
  isEmpty: boolean,
  eventId: string | null,
  data: EventCart | null,
  refresh: () => void,
  loadItems: () => void,
  wineCellarId: string | null,
}

export const initialState: Props = {
  isEmpty: true,
  eventId: null,
  data: null,
  refresh: emptyFunction,
  loadItems: emptyFunction,
  wineCellarId: null,
};

export default createContext<Props>(initialState);
