/* tslint:disable */
/* eslint-disable */
/**
 * Rizov API
 * This document describes Rizov API authentication via [OAuth 2.0](https://oauth.net/2/) [password](https://oauth.net/2/grant-types/password/) grant type method. For React web application standard authentication cookie authentication can be used. For mobile applications OAuth 2.0 authentication is required. For more information about OAuth 2.0 authentication see [RFC 6749](https://tools.ietf.org/html/rfc6749).
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BaseCollection
 */
export interface BaseCollection {
    /**
     * An identifier of the collection.
     * @type {number}
     * @memberof BaseCollection
     */
    id: number;
    /**
     * A price in MDL of the wine in the moment of purchase.
     * @type {number}
     * @memberof BaseCollection
     */
    price: number;
    /**
     * A number of bottles in the collection.
     * @type {number}
     * @memberof BaseCollection
     */
    quantity: number;
    /**
     * A date when the wine was purchased in ISO 8601 format.
     * @type {string}
     * @memberof BaseCollection
     */
    purchase_date: string;
    /**
     * The name of a place where the wine is stored.
     * @type {string}
     * @memberof BaseCollection
     */
    storage: string;
}
/**
 * Represents a base information about the event. This information is not used in any request or response, but it is used as a base for other event types. 
 * @export
 * @interface BaseEvent
 */
export interface BaseEvent {
    /**
     * An event identifier.
     * @type {number}
     * @memberof BaseEvent
     */
    id: number;
    /**
     * A title of the event.
     * @type {string}
     * @memberof BaseEvent
     */
    name: string;
    /**
     * 
     * @type {EventType}
     * @memberof BaseEvent
     */
    type: EventType;
    /**
     * A flag which indicates whether the event allows purchasing of wines.
     * @type {boolean}
     * @memberof BaseEvent
     */
    selling: boolean;
    /**
     * 
     * @type {EventStatus}
     * @memberof BaseEvent
     */
    status?: EventStatus;
}
/**
 * Represents a base information about the wine names. This information is not used in any request or response, but it is used as a base for other wine types. 
 * @export
 * @interface BaseNamedWine
 */
export interface BaseNamedWine {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof BaseNamedWine
     */
    id: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof BaseNamedWine
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof BaseNamedWine
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof BaseNamedWine
     */
    year: number;
}
/**
 * 
 * @export
 * @interface BaseWine
 */
export interface BaseWine {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof BaseWine
     */
    id: number;
    /**
     * 
     * @type {WineNote}
     * @memberof BaseWine
     */
    note: WineNote | null;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof BaseWine
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof BaseWine
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof BaseWine
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof BaseWine
     */
    region: number;
}
/**
 * Represents a base information about the wine. This information is not used in any request or response, but it is used as a base for other wine types. 
 * @export
 * @interface BaseWineAllOf
 */
export interface BaseWineAllOf {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof BaseWineAllOf
     */
    id: number;
    /**
     * 
     * @type {WineNote}
     * @memberof BaseWineAllOf
     */
    note: WineNote | null;
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * An identifier of the collection.
     * @type {number}
     * @memberof Collection
     */
    id: number;
    /**
     * A price in MDL of the wine in the moment of purchase.
     * @type {number}
     * @memberof Collection
     */
    price: number;
    /**
     * A number of bottles in the collection.
     * @type {number}
     * @memberof Collection
     */
    quantity: number;
    /**
     * A date when the wine was purchased in ISO 8601 format.
     * @type {string}
     * @memberof Collection
     */
    purchase_date: string;
    /**
     * The name of a place where the wine is stored.
     * @type {string}
     * @memberof Collection
     */
    storage: string;
    /**
     * A date when the wine will expire in ISO 8601 format. Usually it is 1 year after the purchase date.
     * @type {string}
     * @memberof Collection
     */
    expiration_date: string;
}
/**
 * A collection of wines which are stored in the user\'s cellar.
 * @export
 * @interface CollectionAllOf
 */
export interface CollectionAllOf {
    /**
     * A date when the wine will expire in ISO 8601 format. Usually it is 1 year after the purchase date.
     * @type {string}
     * @memberof CollectionAllOf
     */
    expiration_date: string;
}
/**
 * Contains information about the delivery order. This object is used to create a delivery order. 
 * @export
 * @interface CollectionDeliveryOrder
 */
export interface CollectionDeliveryOrder {
    /**
     * A first name of the user who creates the order.
     * @type {string}
     * @memberof CollectionDeliveryOrder
     */
    first_name: string;
    /**
     * A last name of the user who creates the order.
     * @type {string}
     * @memberof CollectionDeliveryOrder
     */
    last_name: string;
    /**
     * An email address to contact the user who creates the order.
     * @type {string}
     * @memberof CollectionDeliveryOrder
     */
    email: string;
    /**
     * A phone number to contact the user who creates the order.
     * @type {string}
     * @memberof CollectionDeliveryOrder
     */
    phone: string;
    /**
     * A number of bottles from the collection being delivered.
     * @type {number}
     * @memberof CollectionDeliveryOrder
     */
    quantity: number;
}
/**
 * Contains information about the collection exception. 
 * @export
 * @interface CollectionException
 */
export interface CollectionException {
    /**
     * A name of the property which contains an error.
     * @type {string}
     * @memberof CollectionException
     */
    property: CollectionExceptionPropertyEnum;
    /**
     * A minimum value of the property in the specified period.
     * @type {number}
     * @memberof CollectionException
     */
    value_min: number;
    /**
     * A maximum value of the property in the specified period.
     * @type {number}
     * @memberof CollectionException
     */
    value_max: number;
    /**
     * A duration of the exception in seconds.
     * @type {number}
     * @memberof CollectionException
     */
    duration: number;
    /**
     * A date (not a date-time) in ISO 8601 fomat for which the exception information is provided.
     * @type {string}
     * @memberof CollectionException
     */
    date: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CollectionExceptionPropertyEnum {
    Temperature = 'temperature',
    Humidity = 'humidity',
    Illumination = 'illumination'
}

/**
 * 
 * @export
 * @interface CollectionExceptionList
 */
export interface CollectionExceptionList {
    /**
     * 
     * @type {Array<CollectionException>}
     * @memberof CollectionExceptionList
     */
    items: Array<CollectionException>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof CollectionExceptionList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof CollectionExceptionList
     */
    total: number;
}
/**
 * Contains information about the collection exceptions.
 * @export
 * @interface CollectionExceptionListAllOf
 */
export interface CollectionExceptionListAllOf {
    /**
     * 
     * @type {Array<CollectionException>}
     * @memberof CollectionExceptionListAllOf
     */
    items?: Array<CollectionException>;
}
/**
 * Contains information about the collection history entry. 
 * @export
 * @interface CollectionHistoryEntry
 */
export interface CollectionHistoryEntry {
    /**
     * A type of the history entry.
     * @type {string}
     * @memberof CollectionHistoryEntry
     */
    type: CollectionHistoryEntryTypeEnum;
    /**
     * A date (not a date-time) in ISO 8601 fomat for which the history information is provided.
     * @type {string}
     * @memberof CollectionHistoryEntry
     */
    date: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CollectionHistoryEntryTypeEnum {
    Storage = 'storage',
    Owner = 'owner',
    Measurement = 'measurement'
}

/**
 * 
 * @export
 * @interface CollectionHistoryList
 */
export interface CollectionHistoryList {
    /**
     * 
     * @type {Array<StorageCollectionHistoryEntry | OwnerCollectionHistoryEntry | MeasurementCollectionHistoryEntry>}
     * @memberof CollectionHistoryList
     */
    items: Array<StorageCollectionHistoryEntry | OwnerCollectionHistoryEntry | MeasurementCollectionHistoryEntry>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof CollectionHistoryList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof CollectionHistoryList
     */
    total: number;
}
/**
 * Contains information about the collection history.
 * @export
 * @interface CollectionHistoryListAllOf
 */
export interface CollectionHistoryListAllOf {
    /**
     * 
     * @type {Array<StorageCollectionHistoryEntry | OwnerCollectionHistoryEntry | MeasurementCollectionHistoryEntry>}
     * @memberof CollectionHistoryListAllOf
     */
    items?: Array<StorageCollectionHistoryEntry | OwnerCollectionHistoryEntry | MeasurementCollectionHistoryEntry>;
}
/**
 * 
 * @export
 * @interface CollectionList
 */
export interface CollectionList {
    /**
     * 
     * @type {Array<Collection>}
     * @memberof CollectionList
     */
    items: Array<Collection>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof CollectionList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof CollectionList
     */
    total: number;
}
/**
 * A collection of items matching wine \"list\" query.
 * @export
 * @interface CollectionListAllOf
 */
export interface CollectionListAllOf {
    /**
     * 
     * @type {Array<Collection>}
     * @memberof CollectionListAllOf
     */
    items?: Array<Collection>;
}
/**
 * Contains information about sensor measurements in storage where collection is situated. 
 * @export
 * @interface CollectionMeasurements
 */
export interface CollectionMeasurements {
    /**
     * A temperature in the storage.
     * @type {number}
     * @memberof CollectionMeasurements
     */
    temperature: number | null;
    /**
     * A humidity level (in percents) in the storage.
     * @type {number}
     * @memberof CollectionMeasurements
     */
    humidity: number | null;
    /**
     * A illumination level (in lux) in the storage.
     * @type {number}
     * @memberof CollectionMeasurements
     */
    illumination: number | null;
    /**
     * A date and time when the measurements were taken in ISO 8601 format.
     * @type {string}
     * @memberof CollectionMeasurements
     */
    datetime: string;
    /**
     * A bit mask which indicates which inconsistency errors of measurements are present. The bit mask is a sum of the following values: - `0` - No errors. - `1` - The bottles of the collection are stored in different places.
     * @type {number}
     * @memberof CollectionMeasurements
     */
    inconsistency?: number;
}
/**
 * 
 * @export
 * @interface CollectionWithWine
 */
export interface CollectionWithWine {
    /**
     * An identifier of the collection.
     * @type {number}
     * @memberof CollectionWithWine
     */
    id: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof CollectionWithWine
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof CollectionWithWine
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof CollectionWithWine
     */
    year: number;
    /**
     * A price in MDL of the wine in the moment of purchase.
     * @type {number}
     * @memberof CollectionWithWine
     */
    price: number;
    /**
     * A number of bottles in the collection.
     * @type {number}
     * @memberof CollectionWithWine
     */
    quantity: number;
    /**
     * A date when the wine was purchased in ISO 8601 format.
     * @type {string}
     * @memberof CollectionWithWine
     */
    purchase_date: string;
    /**
     * The name of a place where the wine is stored.
     * @type {string}
     * @memberof CollectionWithWine
     */
    storage: string;
    /**
     * An identifier of the wine entity which is stored in the collection.
     * @type {number}
     * @memberof CollectionWithWine
     */
    wine_entity_id: number;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof CollectionWithWine
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof CollectionWithWine
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof CollectionWithWine
     */
    country: number;
}
/**
 * Represents a collection and information about the wine which is stored in the collection.
 * @export
 * @interface CollectionWithWineAllOf
 */
export interface CollectionWithWineAllOf {
    /**
     * An identifier of the wine entity which is stored in the collection.
     * @type {number}
     * @memberof CollectionWithWineAllOf
     */
    wine_entity_id: number;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof CollectionWithWineAllOf
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof CollectionWithWineAllOf
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof CollectionWithWineAllOf
     */
    country: number;
}
/**
 * 
 * @export
 * @interface CollectionWithWineList
 */
export interface CollectionWithWineList {
    /**
     * 
     * @type {Array<CollectionWithWine>}
     * @memberof CollectionWithWineList
     */
    items: Array<CollectionWithWine>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof CollectionWithWineList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof CollectionWithWineList
     */
    total: number;
    /**
     * A total price of all wines in the cart.
     * @type {number}
     * @memberof CollectionWithWineList
     */
    total_price: number;
    /**
     * A total number of bottles in the cart.
     * @type {number}
     * @memberof CollectionWithWineList
     */
    total_bottles: number;
}
/**
 * A collection of items matching wine \"list\" query.
 * @export
 * @interface CollectionWithWineListAllOf
 */
export interface CollectionWithWineListAllOf {
    /**
     * 
     * @type {Array<CollectionWithWine>}
     * @memberof CollectionWithWineListAllOf
     */
    items?: Array<CollectionWithWine>;
    /**
     * A total price of all wines in the cart.
     * @type {number}
     * @memberof CollectionWithWineListAllOf
     */
    total_price: number;
    /**
     * A total number of bottles in the cart.
     * @type {number}
     * @memberof CollectionWithWineListAllOf
     */
    total_bottles: number;
}
/**
 * Contains all information to identify the error occurred.
 * @export
 * @interface ErrorInformation
 */
export interface ErrorInformation {
    /**
     * 
     * @type {ErrorType}
     * @memberof ErrorInformation
     */
    error: ErrorType;
    /**
     * A human-readable short description of the error.
     * @type {string}
     * @memberof ErrorInformation
     */
    error_description: string;
}
/**
 * A machine-readable string which represents a class of error.
 * @export
 * @enum {string}
 */

export enum ErrorType {
    SyntaxError = 'syntax_error',
    AlreadyLoggedIn = 'already_logged_in',
    NotLoggedIn = 'not_logged_in',
    FieldRequired = 'field_required',
    FieldTypeMismatch = 'field_type_mismatch',
    InvalidCredentials = 'invalid_credentials',
    InvalidGrant = 'invalid_grant',
    InvalidRequest = 'invalid_request',
    InvalidScope = 'invalid_scope',
    InvalidToken = 'invalid_token',
    UnsupportedGrantType = 'unsupported_grant_type',
    UnsupportedResponseType = 'unsupported_response_type',
    InsufficientPermissions = 'insufficient_permissions',
    WrongEventCode = 'wrong_event_code',
    UserAlreadyRegistered = 'user_already_registered',
    EventAlreadyFinished = 'event_already_finished',
    WrongEmailAddress = 'wrong_email_address',
    WrongEventType = 'wrong_event_type',
    UsernameMismatch = 'username_mismatch',
    PasswordMismatch = 'password_mismatch',
    UserAlreadyExists = 'user_already_exists',
    InsufficientBottles = 'insufficient_bottles',
    EventVendorNotActive = 'event_vendor_not_active'
}

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * An event identifier.
     * @type {number}
     * @memberof Event
     */
    id: number;
    /**
     * A title of the event.
     * @type {string}
     * @memberof Event
     */
    name: string;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    type: EventType;
    /**
     * A flag which indicates whether the event allows purchasing of wines.
     * @type {boolean}
     * @memberof Event
     */
    selling: boolean;
    /**
     * 
     * @type {EventStatus}
     * @memberof Event
     */
    status: EventStatus;
    /**
     * A long description of the event.
     * @type {string}
     * @memberof Event
     */
    description: string;
    /**
     * A URL to the \"managed file\" which represents the event logo or `null` if the event has no logo.
     * @type {string}
     * @memberof Event
     */
    logo: string;
    /**
     * A list of URLs to the event photos.
     * @type {Array<string>}
     * @memberof Event
     */
    photos: Array<string>;
}
/**
 * A full information about the event.
 * @export
 * @interface EventAllOf
 */
export interface EventAllOf {
    /**
     * A long description of the event.
     * @type {string}
     * @memberof EventAllOf
     */
    description: string;
    /**
     * A URL to the \"managed file\" which represents the event logo or `null` if the event has no logo.
     * @type {string}
     * @memberof EventAllOf
     */
    logo: string;
    /**
     * A list of URLs to the event photos.
     * @type {Array<string>}
     * @memberof EventAllOf
     */
    photos: Array<string>;
    /**
     * 
     * @type {EventStatus}
     * @memberof EventAllOf
     */
    status: EventStatus;
}
/**
 * 
 * @export
 * @interface EventCart
 */
export interface EventCart {
    /**
     * 
     * @type {Array<WineInCart>}
     * @memberof EventCart
     */
    items: Array<WineInCart>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof EventCart
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof EventCart
     */
    total: number;
    /**
     * A total price of all wines in the cart.
     * @type {number}
     * @memberof EventCart
     */
    total_price: number;
    /**
     * A total number of bottles in the cart.
     * @type {number}
     * @memberof EventCart
     */
    total_bottles: number;
}
/**
 * Contains information about the event cart its items and total price.
 * @export
 * @interface EventCartAllOf
 */
export interface EventCartAllOf {
    /**
     * 
     * @type {Array<WineInCart>}
     * @memberof EventCartAllOf
     */
    items?: Array<WineInCart>;
    /**
     * A total price of all wines in the cart.
     * @type {number}
     * @memberof EventCartAllOf
     */
    total_price: number;
    /**
     * A total number of bottles in the cart.
     * @type {number}
     * @memberof EventCartAllOf
     */
    total_bottles: number;
}
/**
 * Contains information contact information about the user who submits the cart. This object is used to submit the cart. 
 * @export
 * @interface EventCartSubmit
 */
export interface EventCartSubmit {
    /**
     * A first name of the user who owns the cart.
     * @type {string}
     * @memberof EventCartSubmit
     */
    first_name: string;
    /**
     * A last name of the user who owns the cart.
     * @type {string}
     * @memberof EventCartSubmit
     */
    last_name: string;
    /**
     * An email address to send notifications about the order.
     * @type {string}
     * @memberof EventCartSubmit
     */
    email: string;
    /**
     * A phone number to contact the user who creates the order.
     * @type {string}
     * @memberof EventCartSubmit
     */
    phone: string;
}
/**
 * A join code for the event. The join code is used to join the event by the user. 
 * @export
 * @interface EventJoinCode
 */
export interface EventJoinCode {
    /**
     * A code to join the event.
     * @type {string}
     * @memberof EventJoinCode
     */
    code: string;
}
/**
 * Represents a status of the event.
 * @export
 * @enum {string}
 */

export enum EventStatus {
    Draft = 'draft',
    InProgress = 'in_progress',
    Finished = 'finished'
}

/**
 * Represents a type of the event.
 * @export
 * @enum {string}
 */

export enum EventType {
    Simple = 'simple',
    TastingBlind = 'tasting_blind',
    TastingFlat = 'tasting_flat',
    TastingGroupedByVineyard = 'tasting_grouped_by_vineyard'
}

/**
 * 
 * @export
 * @interface EventWithBlindTasting
 */
export interface EventWithBlindTasting {
    /**
     * An event identifier.
     * @type {number}
     * @memberof EventWithBlindTasting
     */
    id: number;
    /**
     * A title of the event.
     * @type {string}
     * @memberof EventWithBlindTasting
     */
    name: string;
    /**
     * 
     * @type {EventType}
     * @memberof EventWithBlindTasting
     */
    type: EventType;
    /**
     * A flag which indicates whether the event allows purchasing of wines.
     * @type {boolean}
     * @memberof EventWithBlindTasting
     */
    selling: boolean;
    /**
     * 
     * @type {EventStatus}
     * @memberof EventWithBlindTasting
     */
    status: EventStatus;
    /**
     * A long description of the event.
     * @type {string}
     * @memberof EventWithBlindTasting
     */
    description: string;
    /**
     * A URL to the \"managed file\" which represents the event logo or `null` if the event has no logo.
     * @type {string}
     * @memberof EventWithBlindTasting
     */
    logo: string;
    /**
     * A list of URLs to the event photos.
     * @type {Array<string>}
     * @memberof EventWithBlindTasting
     */
    photos: Array<string>;
    /**
     * 
     * @type {WineForBlindTastingList}
     * @memberof EventWithBlindTasting
     */
    wines: WineForBlindTastingList;
}
/**
 * A full information about the event with blind tasting with first page of wines preloaded.
 * @export
 * @interface EventWithBlindTastingAllOf
 */
export interface EventWithBlindTastingAllOf {
    /**
     * 
     * @type {WineForBlindTastingList}
     * @memberof EventWithBlindTastingAllOf
     */
    wines: WineForBlindTastingList;
}
/**
 * 
 * @export
 * @interface EventWithFlatTasting
 */
export interface EventWithFlatTasting {
    /**
     * An event identifier.
     * @type {number}
     * @memberof EventWithFlatTasting
     */
    id: number;
    /**
     * A title of the event.
     * @type {string}
     * @memberof EventWithFlatTasting
     */
    name: string;
    /**
     * 
     * @type {EventType}
     * @memberof EventWithFlatTasting
     */
    type: EventType;
    /**
     * A flag which indicates whether the event allows purchasing of wines.
     * @type {boolean}
     * @memberof EventWithFlatTasting
     */
    selling: boolean;
    /**
     * 
     * @type {EventStatus}
     * @memberof EventWithFlatTasting
     */
    status: EventStatus;
    /**
     * A long description of the event.
     * @type {string}
     * @memberof EventWithFlatTasting
     */
    description: string;
    /**
     * A URL to the \"managed file\" which represents the event logo or `null` if the event has no logo.
     * @type {string}
     * @memberof EventWithFlatTasting
     */
    logo: string;
    /**
     * A list of URLs to the event photos.
     * @type {Array<string>}
     * @memberof EventWithFlatTasting
     */
    photos: Array<string>;
    /**
     * 
     * @type {WineForTastingList}
     * @memberof EventWithFlatTasting
     */
    wines: WineForTastingList;
}
/**
 * A full information about the event with flat tasting with first page of wines preloaded.
 * @export
 * @interface EventWithFlatTastingAllOf
 */
export interface EventWithFlatTastingAllOf {
    /**
     * 
     * @type {WineForTastingList}
     * @memberof EventWithFlatTastingAllOf
     */
    wines: WineForTastingList;
}
/**
 * 
 * @export
 * @interface EventWithGroupedTasting
 */
export interface EventWithGroupedTasting {
    /**
     * An event identifier.
     * @type {number}
     * @memberof EventWithGroupedTasting
     */
    id: number;
    /**
     * A title of the event.
     * @type {string}
     * @memberof EventWithGroupedTasting
     */
    name: string;
    /**
     * 
     * @type {EventType}
     * @memberof EventWithGroupedTasting
     */
    type: EventType;
    /**
     * A flag which indicates whether the event allows purchasing of wines.
     * @type {boolean}
     * @memberof EventWithGroupedTasting
     */
    selling: boolean;
    /**
     * 
     * @type {EventStatus}
     * @memberof EventWithGroupedTasting
     */
    status: EventStatus;
    /**
     * A long description of the event.
     * @type {string}
     * @memberof EventWithGroupedTasting
     */
    description: string;
    /**
     * A URL to the \"managed file\" which represents the event logo or `null` if the event has no logo.
     * @type {string}
     * @memberof EventWithGroupedTasting
     */
    logo: string;
    /**
     * A list of URLs to the event photos.
     * @type {Array<string>}
     * @memberof EventWithGroupedTasting
     */
    photos: Array<string>;
    /**
     * 
     * @type {VineyardList}
     * @memberof EventWithGroupedTasting
     */
    vineyards: VineyardList;
}
/**
 * A full information about the event with tasting where wines grouped by vineyards. Contains the first page of vineyards preloaded.
 * @export
 * @interface EventWithGroupedTastingAllOf
 */
export interface EventWithGroupedTastingAllOf {
    /**
     * 
     * @type {VineyardList}
     * @memberof EventWithGroupedTastingAllOf
     */
    vineyards: VineyardList;
}
/**
 * information about grape percentage in wine.
 * @export
 * @interface Grape
 */
export interface Grape {
    /**
     * A name of the grape.
     * @type {string}
     * @memberof Grape
     */
    name: string;
    /**
     * A percentage of the grape in wine.
     * @type {number}
     * @memberof Grape
     */
    percent: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * A username of the user trying to authenticate.
     * @type {string}
     * @memberof InlineObject
     */
    username: string;
    /**
     * A password of the user trying to authenticate.
     * @type {string}
     * @memberof InlineObject
     */
    password: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * The URL to redirect the user to the payment page.
     * @type {string}
     * @memberof InlineResponse200
     */
    url: string;
}
/**
 * Represents a collection of items matching \"list\" query.
 * @export
 * @interface List
 */
export interface List {
    /**
     * Items contained in response for \"list\" query.
     * @type {Array<object>}
     * @memberof List
     */
    items: Array<object>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof List
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof List
     */
    total: number;
}
/**
 * 
 * @export
 * @interface MeasurementCollectionHistoryEntry
 */
export interface MeasurementCollectionHistoryEntry extends CollectionHistoryEntry {
    /**
     * A minimum temperature of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntry
     */
    temperature_min: number | null;
    /**
     * A maximum temperature of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntry
     */
    temperature_max: number | null;
    /**
     * A minimum humidity level (in percents) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntry
     */
    humidity_min: number | null;
    /**
     * A maximum humidity level (in percents) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntry
     */
    humidity_max: number | null;
    /**
     * A minimum illumination level (in lux) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntry
     */
    illumination_min: number | null;
    /**
     * A maximum illumination level (in lux) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntry
     */
    illumination_max: number | null;
}


/**
 * Contains information about the measurement collection history entry. 
 * @export
 * @interface MeasurementCollectionHistoryEntryAllOf
 */
export interface MeasurementCollectionHistoryEntryAllOf {
    /**
     * A minimum temperature of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntryAllOf
     */
    temperature_min: number | null;
    /**
     * A maximum temperature of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntryAllOf
     */
    temperature_max: number | null;
    /**
     * A minimum humidity level (in percents) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntryAllOf
     */
    humidity_min: number | null;
    /**
     * A maximum humidity level (in percents) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntryAllOf
     */
    humidity_max: number | null;
    /**
     * A minimum illumination level (in lux) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntryAllOf
     */
    illumination_min: number | null;
    /**
     * A maximum illumination level (in lux) of the wine in specified period.
     * @type {number}
     * @memberof MeasurementCollectionHistoryEntryAllOf
     */
    illumination_max: number | null;
}
/**
 * 
 * @export
 * @interface OwnerCollectionHistoryEntry
 */
export interface OwnerCollectionHistoryEntry extends CollectionHistoryEntry {
    /**
     * An owner of the wine.
     * @type {string}
     * @memberof OwnerCollectionHistoryEntry
     */
    owner: string;
}


/**
 * Contains information about the owner collection history entry. 
 * @export
 * @interface OwnerCollectionHistoryEntryAllOf
 */
export interface OwnerCollectionHistoryEntryAllOf {
    /**
     * An owner of the wine.
     * @type {string}
     * @memberof OwnerCollectionHistoryEntryAllOf
     */
    owner: string;
}
/**
 * A user profile information.
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * A first name of the user.
     * @type {string}
     * @memberof Profile
     */
    first_name: string;
    /**
     * A last name of the user.
     * @type {string}
     * @memberof Profile
     */
    last_name: string;
}
/**
 * A user profile information for deleting the profile.
 * @export
 * @interface ProfileDelete
 */
export interface ProfileDelete {
    /**
     * An email address of the user trying to delete the profile.
     * @type {string}
     * @memberof ProfileDelete
     */
    username?: string;
    /**
     * A password of the user trying to delete the profile.
     * @type {string}
     * @memberof ProfileDelete
     */
    password?: string;
    /**
     * An operation that specifies an account profile for deleting or cleaning up.
     * @type {string}
     * @memberof ProfileDelete
     */
    operation?: ProfileDeleteOperationEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileDeleteOperationEnum {
    Deletion = 'deletion',
    Cleanup = 'cleanup'
}

/**
 * A user profile information for registration.
 * @export
 * @interface ProfileRegistration
 */
export interface ProfileRegistration {
    /**
     * Email address of the new user.
     * @type {string}
     * @memberof ProfileRegistration
     */
    username: string;
    /**
     * A password of the new user.
     * @type {string}
     * @memberof ProfileRegistration
     */
    password: string;
    /**
     * A first name of the new user.
     * @type {string}
     * @memberof ProfileRegistration
     */
    first_name: string;
    /**
     * A last name of the new user.
     * @type {string}
     * @memberof ProfileRegistration
     */
    last_name: string;
}
/**
 * A user profile information for updating display name.
 * @export
 * @interface ProfileUpdateDisplayName
 */
export interface ProfileUpdateDisplayName {
    /**
     * A first name of the user.
     * @type {string}
     * @memberof ProfileUpdateDisplayName
     */
    first_name: string;
    /**
     * A last name of the user.
     * @type {string}
     * @memberof ProfileUpdateDisplayName
     */
    last_name: string;
}
/**
 * A user profile information for updating password.
 * @export
 * @interface ProfileUpdatePassword
 */
export interface ProfileUpdatePassword {
    /**
     * Current password of the user.
     * @type {string}
     * @memberof ProfileUpdatePassword
     */
    old_password: string;
    /**
     * New password of the user.
     * @type {string}
     * @memberof ProfileUpdatePassword
     */
    new_password: string;
}
/**
 * A user profile information for updating username.
 * @export
 * @interface ProfileUpdateUsername
 */
export interface ProfileUpdateUsername {
    /**
     * Current email address of the user.
     * @type {string}
     * @memberof ProfileUpdateUsername
     */
    old_username: string;
    /**
     * New email address of the user.
     * @type {string}
     * @memberof ProfileUpdateUsername
     */
    new_username: string;
}
/**
 * 
 * @export
 * @interface ShortEvent
 */
export interface ShortEvent {
    /**
     * An event identifier.
     * @type {number}
     * @memberof ShortEvent
     */
    id: number;
    /**
     * A title of the event.
     * @type {string}
     * @memberof ShortEvent
     */
    name: string;
    /**
     * 
     * @type {EventType}
     * @memberof ShortEvent
     */
    type: EventType;
    /**
     * A flag which indicates whether the event allows purchasing of wines.
     * @type {boolean}
     * @memberof ShortEvent
     */
    selling: boolean;
    /**
     * 
     * @type {EventStatus}
     * @memberof ShortEvent
     */
    status?: EventStatus;
    /**
     * A date and time when the event starts in ISO 8601 format.
     * @type {string}
     * @memberof ShortEvent
     */
    start_date: string;
    /**
     * A date and time when the event ends in ISO 8601 format.
     * @type {string}
     * @memberof ShortEvent
     */
    end_date: string;
    /**
     * A flag which indicates whether the currently authenticated user has joined the event.
     * @type {boolean}
     * @memberof ShortEvent
     */
    joined: boolean;
}
/**
 * A short event information which is used in list of events.
 * @export
 * @interface ShortEventAllOf
 */
export interface ShortEventAllOf {
    /**
     * A date and time when the event starts in ISO 8601 format.
     * @type {string}
     * @memberof ShortEventAllOf
     */
    start_date: string;
    /**
     * A date and time when the event ends in ISO 8601 format.
     * @type {string}
     * @memberof ShortEventAllOf
     */
    end_date: string;
    /**
     * A flag which indicates whether the currently authenticated user has joined the event.
     * @type {boolean}
     * @memberof ShortEventAllOf
     */
    joined: boolean;
}
/**
 * 
 * @export
 * @interface ShortEventList
 */
export interface ShortEventList {
    /**
     * 
     * @type {Array<ShortEvent>}
     * @memberof ShortEventList
     */
    items: Array<ShortEvent>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof ShortEventList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof ShortEventList
     */
    total: number;
}
/**
 * A collection of items matching event \"list\" query.
 * @export
 * @interface ShortEventListAllOf
 */
export interface ShortEventListAllOf {
    /**
     * 
     * @type {Array<ShortEvent>}
     * @memberof ShortEventListAllOf
     */
    items?: Array<ShortEvent>;
}
/**
 * 
 * @export
 * @interface StorageCollectionHistoryEntry
 */
export interface StorageCollectionHistoryEntry extends CollectionHistoryEntry {
    /**
     * A storage of the wine.
     * @type {string}
     * @memberof StorageCollectionHistoryEntry
     */
    storage: string;
}


/**
 * Contains information about the storage collection history entry. 
 * @export
 * @interface StorageCollectionHistoryEntryAllOf
 */
export interface StorageCollectionHistoryEntryAllOf {
    /**
     * A storage of the wine.
     * @type {string}
     * @memberof StorageCollectionHistoryEntryAllOf
     */
    storage: string;
}
/**
 * Represents a taxonomy term which contains localized name. 
 * @export
 * @interface TaxonomyTerm
 */
export interface TaxonomyTerm {
    /**
     * A taxonomy term identifier (tid).
     * @type {number}
     * @memberof TaxonomyTerm
     */
    id: number;
    /**
     * A localized name of the taxonomy term.
     * @type {string}
     * @memberof TaxonomyTerm
     */
    name: string;
}
/**
 * Contains information about boundaries of conditions in which the wine can be safety stored. 
 * @export
 * @interface TrackingBoundaries
 */
export interface TrackingBoundaries {
    /**
     * The minimum temperature at which wine can be safely stored.
     * @type {number}
     * @memberof TrackingBoundaries
     */
    temperature_min: number;
    /**
     * The maximum temperature at which wine can be safely stored.
     * @type {number}
     * @memberof TrackingBoundaries
     */
    temperature_max: number;
    /**
     * The minimum humidity level (in percents) at which wine can be safely stored.
     * @type {number}
     * @memberof TrackingBoundaries
     */
    humidity_min: number;
    /**
     * The maximum humidity level (in percents) at which wine can be safely stored.
     * @type {number}
     * @memberof TrackingBoundaries
     */
    humidity_max: number;
    /**
     * The minimum illumination level (in lux) at which wine can be safely stored.
     * @type {number}
     * @memberof TrackingBoundaries
     */
    illumination_min: number;
    /**
     * The maximum illumination level (in lux) at which wine can be safely stored.
     * @type {number}
     * @memberof TrackingBoundaries
     */
    illumination_max: number;
}
/**
 * 
 * @export
 * @interface ValidationErrorInformation
 */
export interface ValidationErrorInformation {
    /**
     * 
     * @type {ErrorType}
     * @memberof ValidationErrorInformation
     */
    error: ErrorType;
    /**
     * A human-readable short description of the error.
     * @type {string}
     * @memberof ValidationErrorInformation
     */
    error_description: string;
    /**
     * A name of the field which contains an error.
     * @type {string}
     * @memberof ValidationErrorInformation
     */
    field_name?: string;
    /**
     * A path to the field which contains an error.
     * @type {string}
     * @memberof ValidationErrorInformation
     */
    field_path?: string;
}
/**
 * 
 * @export
 * @interface ValidationErrorInformationAllOf
 */
export interface ValidationErrorInformationAllOf {
    /**
     * A name of the field which contains an error.
     * @type {string}
     * @memberof ValidationErrorInformationAllOf
     */
    field_name?: string;
    /**
     * A path to the field which contains an error.
     * @type {string}
     * @memberof ValidationErrorInformationAllOf
     */
    field_path?: string;
}
/**
 * Represents information about the vineyard.
 * @export
 * @interface Vineyard
 */
export interface Vineyard {
    /**
     * A vineyard identifier.
     * @type {number}
     * @memberof Vineyard
     */
    id: number;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof Vineyard
     */
    name: string;
    /**
     * A URL to the \"managed file\" which represents the vineyard logo or `null` if the vineyard has no logo.
     * @type {string}
     * @memberof Vineyard
     */
    logo?: string;
}
/**
 * 
 * @export
 * @interface VineyardList
 */
export interface VineyardList {
    /**
     * 
     * @type {Array<Vineyard>}
     * @memberof VineyardList
     */
    items: Array<Vineyard>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof VineyardList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof VineyardList
     */
    total: number;
}
/**
 * A collection of items matching vineyard \"list\" query.
 * @export
 * @interface VineyardListAllOf
 */
export interface VineyardListAllOf {
    /**
     * 
     * @type {Array<Vineyard>}
     * @memberof VineyardListAllOf
     */
    items?: Array<Vineyard>;
}
/**
 * 
 * @export
 * @interface WineCartSellingInfo
 */
export interface WineCartSellingInfo {
    /**
     * A price of the wine in MDL.
     * @type {number}
     * @memberof WineCartSellingInfo
     */
    price: number;
    /**
     * A number of bottles which are available for purchase.
     * @type {number}
     * @memberof WineCartSellingInfo
     */
    remain: number;
    /**
     * A number of bottles which are added to the cart.
     * @type {number}
     * @memberof WineCartSellingInfo
     */
    quantity: number;
}
/**
 * An information about the wine which is added to the cart.
 * @export
 * @interface WineCartSellingInfoAllOf
 */
export interface WineCartSellingInfoAllOf {
    /**
     * A number of bottles which are added to the cart.
     * @type {number}
     * @memberof WineCartSellingInfoAllOf
     */
    quantity: number;
}
/**
 * A number of bottles which are added to the cart.
 * @export
 * @interface WineCartSellingInfoUpdate
 */
export interface WineCartSellingInfoUpdate {
    /**
     * A number of bottles which being added to the cart.
     * @type {number}
     * @memberof WineCartSellingInfoUpdate
     */
    quantity: number;
}
/**
 * 
 * @export
 * @interface WineForBlindTasting
 */
export interface WineForBlindTasting {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof WineForBlindTasting
     */
    id: number;
    /**
     * 
     * @type {WineNote}
     * @memberof WineForBlindTasting
     */
    note: WineNote | null;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineForBlindTasting
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineForBlindTasting
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineForBlindTasting
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineForBlindTasting
     */
    region: number;
    /**
     * A position of the wine in the blind tasting.
     * @type {number}
     * @memberof WineForBlindTasting
     */
    position: number;
}
/**
 * A full information about the wine which participates in the event with blind tasting.
 * @export
 * @interface WineForBlindTastingAllOf
 */
export interface WineForBlindTastingAllOf {
    /**
     * A position of the wine in the blind tasting.
     * @type {number}
     * @memberof WineForBlindTastingAllOf
     */
    position: number;
}
/**
 * 
 * @export
 * @interface WineForBlindTastingList
 */
export interface WineForBlindTastingList {
    /**
     * 
     * @type {Array<WineForBlindTasting>}
     * @memberof WineForBlindTastingList
     */
    items: Array<WineForBlindTasting>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof WineForBlindTastingList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof WineForBlindTastingList
     */
    total: number;
}
/**
 * A collection of items matching wine \"list\" query.
 * @export
 * @interface WineForBlindTastingListAllOf
 */
export interface WineForBlindTastingListAllOf {
    /**
     * 
     * @type {Array<WineForBlindTasting>}
     * @memberof WineForBlindTastingListAllOf
     */
    items?: Array<WineForBlindTasting>;
}
/**
 * 
 * @export
 * @interface WineForTasting
 */
export interface WineForTasting {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof WineForTasting
     */
    id: number;
    /**
     * 
     * @type {WineNote}
     * @memberof WineForTasting
     */
    note: WineNote | null;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineForTasting
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineForTasting
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineForTasting
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineForTasting
     */
    region: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof WineForTasting
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof WineForTasting
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof WineForTasting
     */
    year: number;
}
/**
 * 
 * @export
 * @interface WineForTastingList
 */
export interface WineForTastingList {
    /**
     * 
     * @type {Array<WineForTasting>}
     * @memberof WineForTastingList
     */
    items: Array<WineForTasting>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof WineForTastingList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof WineForTastingList
     */
    total: number;
}
/**
 * A collection of items matching wine \"list\" query.
 * @export
 * @interface WineForTastingListAllOf
 */
export interface WineForTastingListAllOf {
    /**
     * 
     * @type {Array<WineForTasting>}
     * @memberof WineForTastingListAllOf
     */
    items?: Array<WineForTasting>;
}
/**
 * 
 * @export
 * @interface WineInCart
 */
export interface WineInCart {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof WineInCart
     */
    id: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof WineInCart
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof WineInCart
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof WineInCart
     */
    year: number;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineInCart
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineInCart
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineInCart
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineInCart
     */
    region: number;
    /**
     * 
     * @type {WineRating}
     * @memberof WineInCart
     */
    rating: WineRating;
    /**
     * 
     * @type {WineCartSellingInfo}
     * @memberof WineInCart
     */
    selling: WineCartSellingInfo;
}
/**
 * 
 * @export
 * @interface WineInCartAllOf
 */
export interface WineInCartAllOf {
    /**
     * 
     * @type {WineCartSellingInfo}
     * @memberof WineInCartAllOf
     */
    selling: WineCartSellingInfo;
}
/**
 * 
 * @export
 * @interface WineInCollection
 */
export interface WineInCollection {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof WineInCollection
     */
    id: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof WineInCollection
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof WineInCollection
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof WineInCollection
     */
    year: number;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineInCollection
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineInCollection
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineInCollection
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineInCollection
     */
    region: number;
    /**
     * A percentage of alcohol in the wine.
     * @type {number}
     * @memberof WineInCollection
     */
    alcohol: number;
    /**
     * A quantity of sugar in grams per liter.
     * @type {number}
     * @memberof WineInCollection
     */
    sugar: number;
    /**
     * A list of grapes and their percentages which were used to produce the wine.
     * @type {Array<Grape>}
     * @memberof WineInCollection
     */
    grapes: Array<Grape>;
    /**
     * 
     * @type {CollectionList}
     * @memberof WineInCollection
     */
    collections: CollectionList;
}
/**
 * 
 * @export
 * @interface WineInCollectionAllOf
 */
export interface WineInCollectionAllOf {
    /**
     * A percentage of alcohol in the wine.
     * @type {number}
     * @memberof WineInCollectionAllOf
     */
    alcohol: number;
    /**
     * A quantity of sugar in grams per liter.
     * @type {number}
     * @memberof WineInCollectionAllOf
     */
    sugar: number;
    /**
     * A list of grapes and their percentages which were used to produce the wine.
     * @type {Array<Grape>}
     * @memberof WineInCollectionAllOf
     */
    grapes: Array<Grape>;
    /**
     * 
     * @type {CollectionList}
     * @memberof WineInCollectionAllOf
     */
    collections: CollectionList;
}
/**
 * A note about the wine which was tasted during the event.
 * @export
 * @interface WineNote
 */
export interface WineNote {
    /**
     * A note about the wine written by the user during the event.
     * @type {string}
     * @memberof WineNote
     */
    comment?: string;
    /**
     * A mark of the wine given by the user during the event.
     * @type {number}
     * @memberof WineNote
     */
    mark: number;
}
/**
 * A full information about the wine with rating and note.
 * @export
 * @interface WineProperties
 */
export interface WineProperties {
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineProperties
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineProperties
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineProperties
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineProperties
     */
    region: number;
}
/**
 * A rating of the wine gotten during the event.
 * @export
 * @interface WineRating
 */
export interface WineRating {
    /**
     * A rating of the wine given by the user during the event.
     * @type {number}
     * @memberof WineRating
     */
    user: number | null;
    /**
     * An average rating of the wine given by all users during the event.
     * @type {number}
     * @memberof WineRating
     */
    average: number;
    /**
     * A note about the wine written by the user during the event.
     * @type {string}
     * @memberof WineRating
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface WineResult
 */
export interface WineResult {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof WineResult
     */
    id: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof WineResult
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof WineResult
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof WineResult
     */
    year: number;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineResult
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineResult
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineResult
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineResult
     */
    region: number;
    /**
     * 
     * @type {WineRating}
     * @memberof WineResult
     */
    rating: WineRating;
}
/**
 * A full information about the wine with rating.
 * @export
 * @interface WineResultAllOf
 */
export interface WineResultAllOf {
    /**
     * 
     * @type {WineRating}
     * @memberof WineResultAllOf
     */
    rating: WineRating;
}
/**
 * 
 * @export
 * @interface WineResultList
 */
export interface WineResultList {
    /**
     * 
     * @type {Array<WineResult>}
     * @memberof WineResultList
     */
    items: Array<WineResult>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof WineResultList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof WineResultList
     */
    total: number;
}
/**
 * A collection of items matching wine \"list\" query.
 * @export
 * @interface WineResultListAllOf
 */
export interface WineResultListAllOf {
    /**
     * 
     * @type {Array<WineResult>}
     * @memberof WineResultListAllOf
     */
    items?: Array<WineResult>;
}
/**
 * 
 * @export
 * @interface WineResultWithSelling
 */
export interface WineResultWithSelling {
    /**
     * A wine identifier.
     * @type {number}
     * @memberof WineResultWithSelling
     */
    id: number;
    /**
     * A name of the wine.
     * @type {string}
     * @memberof WineResultWithSelling
     */
    name: string;
    /**
     * A name of the vineyard.
     * @type {string}
     * @memberof WineResultWithSelling
     */
    vineyard: string;
    /**
     * A year when the wine was produced.
     * @type {number}
     * @memberof WineResultWithSelling
     */
    year: number;
    /**
     * A URL to the \"managed file\" which represents the wine photo or `null` if the wine has no photo.
     * @type {string}
     * @memberof WineResultWithSelling
     */
    photo: string | null;
    /**
     * A list of wine categories.
     * @type {Array<number>}
     * @memberof WineResultWithSelling
     */
    category: Array<number>;
    /**
     * Identifier of taxonomy term which represents a country where the wine was produced.
     * @type {number}
     * @memberof WineResultWithSelling
     */
    country: number;
    /**
     * Identifier of taxonomy term which represents a region where the wine was produced.
     * @type {number}
     * @memberof WineResultWithSelling
     */
    region: number;
    /**
     * 
     * @type {WineRating}
     * @memberof WineResultWithSelling
     */
    rating: WineRating;
    /**
     * 
     * @type {WineCartSellingInfo}
     * @memberof WineResultWithSelling
     */
    selling: WineCartSellingInfo;
}
/**
 * A full information about the wine with rating which can be purchased by the user.
 * @export
 * @interface WineResultWithSellingAllOf
 */
export interface WineResultWithSellingAllOf {
    /**
     * 
     * @type {WineCartSellingInfo}
     * @memberof WineResultWithSellingAllOf
     */
    selling: WineCartSellingInfo;
}
/**
 * 
 * @export
 * @interface WineResultWithSellingList
 */
export interface WineResultWithSellingList {
    /**
     * 
     * @type {Array<WineResultWithSelling>}
     * @memberof WineResultWithSellingList
     */
    items: Array<WineResultWithSelling>;
    /**
     * Number of items skipped before server starts to return objects from the request query.
     * @type {number}
     * @memberof WineResultWithSellingList
     */
    offset: number;
    /**
     * Total number of items matching \"list\" query.
     * @type {number}
     * @memberof WineResultWithSellingList
     */
    total: number;
}
/**
 * A collection of items matching wine \"list\" query.
 * @export
 * @interface WineResultWithSellingListAllOf
 */
export interface WineResultWithSellingListAllOf {
    /**
     * 
     * @type {Array<WineResultWithSelling>}
     * @memberof WineResultWithSellingListAllOf
     */
    items?: Array<WineResultWithSelling>;
}
/**
 * A selling information of the wine.
 * @export
 * @interface WineSellingInfo
 */
export interface WineSellingInfo {
    /**
     * A price of the wine in MDL.
     * @type {number}
     * @memberof WineSellingInfo
     */
    price: number;
    /**
     * A number of bottles which are available for purchase.
     * @type {number}
     * @memberof WineSellingInfo
     */
    remain: number;
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get information about the event with the specified ID. The response contains all needed information about the event, its wine list or the list of vineayrds. If the event has `tasting_grouped_by_vineyard` type, the response contains the list of vineayrds in `vineyards` field, otherwise the response contains the wine list in `wines` one. Parameters `offset` and `limit` are used to paginate the wine list or the list of vineayrds. 
         * @summary Get event information
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventIdGet: async (eventId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventEventIdGet', 'eventId', eventId)
            const localVarPath = `/event/{event_id}`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Join the event with the specified ID. The user must be logged in to perform this request.
         * @summary Join event
         * @param {number} eventId An identifier of the event.
         * @param {EventJoinCode} [eventJoinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventIdJoinPost: async (eventId: number, eventJoinCode?: EventJoinCode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventEventIdJoinPost', 'eventId', eventId)
            const localVarPath = `/event/{event_id}/join`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventJoinCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of active or finished events. The active event is an event has not yet ended. If the `finished` parameter is set to `true`, the server will return only that finished events in which the user participated. 
         * @summary Get list of events
         * @param {boolean} [finished] Determines whether finished events should be returned.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventListGet: async (finished?: boolean, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (finished !== undefined) {
                localVarQueryParameter['finished'] = finished;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the results of the event with the specified ID. Regardless of event type, the response contains the list of wines with names needed to identify ones. The user must be logged in to perform this request. 
         * @summary Get event results
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventResults: async (eventId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventResults', 'eventId', eventId)
            const localVarPath = `/event/{event_id}/results`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of vineyards in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
         * @summary List event vineyards
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventVineyards: async (eventId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventVineyards', 'eventId', eventId)
            const localVarPath = `/event/{event_id}/vineyard/list`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of wines in the event with the specified ID. This method can be called only for events with `tasting_flat` or `tasting_blind` type. The user must be logged in to perform this request. 
         * @summary List event wines
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventWines: async (eventId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventWines', 'eventId', eventId)
            const localVarPath = `/event/{event_id}/wine/list`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of wines for the vineyard with the specified ID in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
         * @summary Get event wines by vineyard
         * @param {number} eventId An identifier of the event.
         * @param {number} vineyardId An identifier of the vineyard.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventWinesByVineyard: async (eventId: number, vineyardId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventWinesByVineyard', 'eventId', eventId)
            // verify required parameter 'vineyardId' is not null or undefined
            assertParamExists('getEventWinesByVineyard', 'vineyardId', vineyardId)
            const localVarPath = `/event/{event_id}/vineyard/{vineyard_id}/wine/list`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"vineyard_id"}}`, encodeURIComponent(String(vineyardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the note of the wine with the specified ID. The user must be logged in to perform this request. 
         * @summary Get wine note
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWineNote: async (eventId: number, wineId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getWineNote', 'eventId', eventId)
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('getWineNote', 'wineId', wineId)
            const localVarPath = `/event/{event_id}/wine/{wine_id}/note`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update the note of the wine with the specified ID. The user must be logged in to perform this request. 
         * @summary Set wine note
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineNote} wineNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWineNote: async (eventId: number, wineId: number, wineNote: WineNote, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('setWineNote', 'eventId', eventId)
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('setWineNote', 'wineId', wineId)
            // verify required parameter 'wineNote' is not null or undefined
            assertParamExists('setWineNote', 'wineNote', wineNote)
            const localVarPath = `/event/{event_id}/wine/{wine_id}/note`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wineNote, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get information about the event with the specified ID. The response contains all needed information about the event, its wine list or the list of vineayrds. If the event has `tasting_grouped_by_vineyard` type, the response contains the list of vineayrds in `vineyards` field, otherwise the response contains the wine list in `wines` one. Parameters `offset` and `limit` are used to paginate the wine list or the list of vineayrds. 
         * @summary Get event information
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventEventIdGet(eventId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventWithFlatTasting | EventWithBlindTasting | EventWithGroupedTasting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventEventIdGet(eventId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Join the event with the specified ID. The user must be logged in to perform this request.
         * @summary Join event
         * @param {number} eventId An identifier of the event.
         * @param {EventJoinCode} [eventJoinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventEventIdJoinPost(eventId: number, eventJoinCode?: EventJoinCode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventEventIdJoinPost(eventId, eventJoinCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get list of active or finished events. The active event is an event has not yet ended. If the `finished` parameter is set to `true`, the server will return only that finished events in which the user participated. 
         * @summary Get list of events
         * @param {boolean} [finished] Determines whether finished events should be returned.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventListGet(finished?: boolean, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortEventList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventListGet(finished, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the results of the event with the specified ID. Regardless of event type, the response contains the list of wines with names needed to identify ones. The user must be logged in to perform this request. 
         * @summary Get event results
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventResults(eventId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineResultList | WineResultWithSellingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventResults(eventId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of vineyards in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
         * @summary List event vineyards
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventVineyards(eventId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VineyardList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventVineyards(eventId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of wines in the event with the specified ID. This method can be called only for events with `tasting_flat` or `tasting_blind` type. The user must be logged in to perform this request. 
         * @summary List event wines
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventWines(eventId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineForTastingList | WineForBlindTastingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventWines(eventId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of wines for the vineyard with the specified ID in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
         * @summary Get event wines by vineyard
         * @param {number} eventId An identifier of the event.
         * @param {number} vineyardId An identifier of the vineyard.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventWinesByVineyard(eventId: number, vineyardId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineForTastingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventWinesByVineyard(eventId, vineyardId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the note of the wine with the specified ID. The user must be logged in to perform this request. 
         * @summary Get wine note
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWineNote(eventId: number, wineId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWineNote(eventId, wineId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update the note of the wine with the specified ID. The user must be logged in to perform this request. 
         * @summary Set wine note
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineNote} wineNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setWineNote(eventId: number, wineId: number, wineNote: WineNote, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setWineNote(eventId, wineId, wineNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * Get information about the event with the specified ID. The response contains all needed information about the event, its wine list or the list of vineayrds. If the event has `tasting_grouped_by_vineyard` type, the response contains the list of vineayrds in `vineyards` field, otherwise the response contains the wine list in `wines` one. Parameters `offset` and `limit` are used to paginate the wine list or the list of vineayrds. 
         * @summary Get event information
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventIdGet(eventId: number, offset?: number, limit?: number, options?: any): AxiosPromise<EventWithFlatTasting | EventWithBlindTasting | EventWithGroupedTasting> {
            return localVarFp.eventEventIdGet(eventId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Join the event with the specified ID. The user must be logged in to perform this request.
         * @summary Join event
         * @param {number} eventId An identifier of the event.
         * @param {EventJoinCode} [eventJoinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventIdJoinPost(eventId: number, eventJoinCode?: EventJoinCode, options?: any): AxiosPromise<void> {
            return localVarFp.eventEventIdJoinPost(eventId, eventJoinCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of active or finished events. The active event is an event has not yet ended. If the `finished` parameter is set to `true`, the server will return only that finished events in which the user participated. 
         * @summary Get list of events
         * @param {boolean} [finished] Determines whether finished events should be returned.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventListGet(finished?: boolean, offset?: number, limit?: number, options?: any): AxiosPromise<ShortEventList> {
            return localVarFp.eventListGet(finished, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the results of the event with the specified ID. Regardless of event type, the response contains the list of wines with names needed to identify ones. The user must be logged in to perform this request. 
         * @summary Get event results
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventResults(eventId: number, offset?: number, limit?: number, options?: any): AxiosPromise<WineResultList | WineResultWithSellingList> {
            return localVarFp.getEventResults(eventId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of vineyards in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
         * @summary List event vineyards
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventVineyards(eventId: number, offset?: number, limit?: number, options?: any): AxiosPromise<VineyardList> {
            return localVarFp.getEventVineyards(eventId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of wines in the event with the specified ID. This method can be called only for events with `tasting_flat` or `tasting_blind` type. The user must be logged in to perform this request. 
         * @summary List event wines
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventWines(eventId: number, offset?: number, limit?: number, options?: any): AxiosPromise<WineForTastingList | WineForBlindTastingList> {
            return localVarFp.getEventWines(eventId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of wines for the vineyard with the specified ID in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
         * @summary Get event wines by vineyard
         * @param {number} eventId An identifier of the event.
         * @param {number} vineyardId An identifier of the vineyard.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventWinesByVineyard(eventId: number, vineyardId: number, offset?: number, limit?: number, options?: any): AxiosPromise<WineForTastingList> {
            return localVarFp.getEventWinesByVineyard(eventId, vineyardId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the note of the wine with the specified ID. The user must be logged in to perform this request. 
         * @summary Get wine note
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWineNote(eventId: number, wineId: number, options?: any): AxiosPromise<WineNote> {
            return localVarFp.getWineNote(eventId, wineId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update the note of the wine with the specified ID. The user must be logged in to perform this request. 
         * @summary Set wine note
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineNote} wineNote 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWineNote(eventId: number, wineId: number, wineNote: WineNote, options?: any): AxiosPromise<void> {
            return localVarFp.setWineNote(eventId, wineId, wineNote, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * Get information about the event with the specified ID. The response contains all needed information about the event, its wine list or the list of vineayrds. If the event has `tasting_grouped_by_vineyard` type, the response contains the list of vineayrds in `vineyards` field, otherwise the response contains the wine list in `wines` one. Parameters `offset` and `limit` are used to paginate the wine list or the list of vineayrds. 
     * @summary Get event information
     * @param {number} eventId An identifier of the event.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public eventEventIdGet(eventId: number, offset?: number, limit?: number, options?: any) {
        return EventsApiFp(this.configuration).eventEventIdGet(eventId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Join the event with the specified ID. The user must be logged in to perform this request.
     * @summary Join event
     * @param {number} eventId An identifier of the event.
     * @param {EventJoinCode} [eventJoinCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public eventEventIdJoinPost(eventId: number, eventJoinCode?: EventJoinCode, options?: any) {
        return EventsApiFp(this.configuration).eventEventIdJoinPost(eventId, eventJoinCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of active or finished events. The active event is an event has not yet ended. If the `finished` parameter is set to `true`, the server will return only that finished events in which the user participated. 
     * @summary Get list of events
     * @param {boolean} [finished] Determines whether finished events should be returned.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public eventListGet(finished?: boolean, offset?: number, limit?: number, options?: any) {
        return EventsApiFp(this.configuration).eventListGet(finished, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the results of the event with the specified ID. Regardless of event type, the response contains the list of wines with names needed to identify ones. The user must be logged in to perform this request. 
     * @summary Get event results
     * @param {number} eventId An identifier of the event.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventResults(eventId: number, offset?: number, limit?: number, options?: any) {
        return EventsApiFp(this.configuration).getEventResults(eventId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of vineyards in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
     * @summary List event vineyards
     * @param {number} eventId An identifier of the event.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventVineyards(eventId: number, offset?: number, limit?: number, options?: any) {
        return EventsApiFp(this.configuration).getEventVineyards(eventId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of wines in the event with the specified ID. This method can be called only for events with `tasting_flat` or `tasting_blind` type. The user must be logged in to perform this request. 
     * @summary List event wines
     * @param {number} eventId An identifier of the event.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventWines(eventId: number, offset?: number, limit?: number, options?: any) {
        return EventsApiFp(this.configuration).getEventWines(eventId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of wines for the vineyard with the specified ID in the event with the specified ID. This method can be called only for events with `tasting_grouped_by_vineyard` type. The user must be logged in to perform this request. 
     * @summary Get event wines by vineyard
     * @param {number} eventId An identifier of the event.
     * @param {number} vineyardId An identifier of the vineyard.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventWinesByVineyard(eventId: number, vineyardId: number, offset?: number, limit?: number, options?: any) {
        return EventsApiFp(this.configuration).getEventWinesByVineyard(eventId, vineyardId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the note of the wine with the specified ID. The user must be logged in to perform this request. 
     * @summary Get wine note
     * @param {number} eventId An identifier of the event.
     * @param {number} wineId An identifier of the wine.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getWineNote(eventId: number, wineId: number, options?: any) {
        return EventsApiFp(this.configuration).getWineNote(eventId, wineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update the note of the wine with the specified ID. The user must be logged in to perform this request. 
     * @summary Set wine note
     * @param {number} eventId An identifier of the event.
     * @param {number} wineId An identifier of the wine.
     * @param {WineNote} wineNote 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public setWineNote(eventId: number, wineId: number, wineNote: WineNote, options?: any) {
        return EventsApiFp(this.configuration).setWineNote(eventId, wineId, wineNote, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketApi - axios parameter creator
 * @export
 */
export const MarketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add the wine with the specified ID to the cart of the event with the specified ID. If the wine is already in the cart, its quantity will be increased by specified value. 
         * @summary Add wine to event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWineToEventCart: async (eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('addWineToEventCart', 'eventId', eventId)
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('addWineToEventCart', 'wineId', wineId)
            const localVarPath = `/event/{event_id}/cart/item/{wine_id}`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wineCartSellingInfoUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the wine with the specified ID from the cart of the event with the specified ID. If the wine is not in the cart, nothing will be done. 
         * @summary Delete wine from event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWineFromEventCart: async (eventId: number, wineId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteWineFromEventCart', 'eventId', eventId)
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('deleteWineFromEventCart', 'wineId', wineId)
            const localVarPath = `/event/{event_id}/cart/item/{wine_id}`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit the cart of the event with the specified identifier. 
         * @summary Submit event cart
         * @param {number} eventId An identifier of the event.
         * @param {string} [redirectUri] A URL to redirect the user to after the cart has been submitted successfully. If the URL is not specified, the user will be redirected to default page. 
         * @param {EventCartSubmit} [eventCartSubmit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventIdCartPost: async (eventId: number, redirectUri?: string, eventCartSubmit?: EventCartSubmit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventEventIdCartPost', 'eventId', eventId)
            const localVarPath = `/event/{event_id}/cart`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventCartSubmit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the cart of the event with the specified ID. The user must be logged in to perform this request. Client itself should calculate the total price of the cart. 
         * @summary Get event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCart: async (eventId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventCart', 'eventId', eventId)
            const localVarPath = `/event/{event_id}/cart`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update quantity of the wine with the specified ID in the cart of the event with the specified ID. Be careful, if the wine is not in the cart, it will not be added to the cart. 
         * @summary Update wine in event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWineInEventCart: async (eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateWineInEventCart', 'eventId', eventId)
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('updateWineInEventCart', 'wineId', wineId)
            const localVarPath = `/event/{event_id}/cart/item/{wine_id}`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wineCartSellingInfoUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketApi - functional programming interface
 * @export
 */
export const MarketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketApiAxiosParamCreator(configuration)
    return {
        /**
         * Add the wine with the specified ID to the cart of the event with the specified ID. If the wine is already in the cart, its quantity will be increased by specified value. 
         * @summary Add wine to event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWineToEventCart(eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineCartSellingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addWineToEventCart(eventId, wineId, wineCartSellingInfoUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the wine with the specified ID from the cart of the event with the specified ID. If the wine is not in the cart, nothing will be done. 
         * @summary Delete wine from event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWineFromEventCart(eventId: number, wineId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWineFromEventCart(eventId, wineId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit the cart of the event with the specified identifier. 
         * @summary Submit event cart
         * @param {number} eventId An identifier of the event.
         * @param {string} [redirectUri] A URL to redirect the user to after the cart has been submitted successfully. If the URL is not specified, the user will be redirected to default page. 
         * @param {EventCartSubmit} [eventCartSubmit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventEventIdCartPost(eventId: number, redirectUri?: string, eventCartSubmit?: EventCartSubmit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventEventIdCartPost(eventId, redirectUri, eventCartSubmit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the cart of the event with the specified ID. The user must be logged in to perform this request. Client itself should calculate the total price of the cart. 
         * @summary Get event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventCart(eventId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventCart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventCart(eventId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update quantity of the wine with the specified ID in the cart of the event with the specified ID. Be careful, if the wine is not in the cart, it will not be added to the cart. 
         * @summary Update wine in event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWineInEventCart(eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineCartSellingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWineInEventCart(eventId, wineId, wineCartSellingInfoUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketApi - factory interface
 * @export
 */
export const MarketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketApiFp(configuration)
    return {
        /**
         * Add the wine with the specified ID to the cart of the event with the specified ID. If the wine is already in the cart, its quantity will be increased by specified value. 
         * @summary Add wine to event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWineToEventCart(eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options?: any): AxiosPromise<WineCartSellingInfo> {
            return localVarFp.addWineToEventCart(eventId, wineId, wineCartSellingInfoUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the wine with the specified ID from the cart of the event with the specified ID. If the wine is not in the cart, nothing will be done. 
         * @summary Delete wine from event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWineFromEventCart(eventId: number, wineId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWineFromEventCart(eventId, wineId, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit the cart of the event with the specified identifier. 
         * @summary Submit event cart
         * @param {number} eventId An identifier of the event.
         * @param {string} [redirectUri] A URL to redirect the user to after the cart has been submitted successfully. If the URL is not specified, the user will be redirected to default page. 
         * @param {EventCartSubmit} [eventCartSubmit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEventIdCartPost(eventId: number, redirectUri?: string, eventCartSubmit?: EventCartSubmit, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.eventEventIdCartPost(eventId, redirectUri, eventCartSubmit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the cart of the event with the specified ID. The user must be logged in to perform this request. Client itself should calculate the total price of the cart. 
         * @summary Get event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventCart(eventId: number, offset?: number, limit?: number, options?: any): AxiosPromise<EventCart> {
            return localVarFp.getEventCart(eventId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Update quantity of the wine with the specified ID in the cart of the event with the specified ID. Be careful, if the wine is not in the cart, it will not be added to the cart. 
         * @summary Update wine in event cart
         * @param {number} eventId An identifier of the event.
         * @param {number} wineId An identifier of the wine.
         * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWineInEventCart(eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options?: any): AxiosPromise<WineCartSellingInfo> {
            return localVarFp.updateWineInEventCart(eventId, wineId, wineCartSellingInfoUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketApi - object-oriented interface
 * @export
 * @class MarketApi
 * @extends {BaseAPI}
 */
export class MarketApi extends BaseAPI {
    /**
     * Add the wine with the specified ID to the cart of the event with the specified ID. If the wine is already in the cart, its quantity will be increased by specified value. 
     * @summary Add wine to event cart
     * @param {number} eventId An identifier of the event.
     * @param {number} wineId An identifier of the wine.
     * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public addWineToEventCart(eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options?: any) {
        return MarketApiFp(this.configuration).addWineToEventCart(eventId, wineId, wineCartSellingInfoUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the wine with the specified ID from the cart of the event with the specified ID. If the wine is not in the cart, nothing will be done. 
     * @summary Delete wine from event cart
     * @param {number} eventId An identifier of the event.
     * @param {number} wineId An identifier of the wine.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public deleteWineFromEventCart(eventId: number, wineId: number, options?: any) {
        return MarketApiFp(this.configuration).deleteWineFromEventCart(eventId, wineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit the cart of the event with the specified identifier. 
     * @summary Submit event cart
     * @param {number} eventId An identifier of the event.
     * @param {string} [redirectUri] A URL to redirect the user to after the cart has been submitted successfully. If the URL is not specified, the user will be redirected to default page. 
     * @param {EventCartSubmit} [eventCartSubmit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public eventEventIdCartPost(eventId: number, redirectUri?: string, eventCartSubmit?: EventCartSubmit, options?: any) {
        return MarketApiFp(this.configuration).eventEventIdCartPost(eventId, redirectUri, eventCartSubmit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the cart of the event with the specified ID. The user must be logged in to perform this request. Client itself should calculate the total price of the cart. 
     * @summary Get event cart
     * @param {number} eventId An identifier of the event.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public getEventCart(eventId: number, offset?: number, limit?: number, options?: any) {
        return MarketApiFp(this.configuration).getEventCart(eventId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update quantity of the wine with the specified ID in the cart of the event with the specified ID. Be careful, if the wine is not in the cart, it will not be added to the cart. 
     * @summary Update wine in event cart
     * @param {number} eventId An identifier of the event.
     * @param {number} wineId An identifier of the wine.
     * @param {WineCartSellingInfoUpdate} [wineCartSellingInfoUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketApi
     */
    public updateWineInEventCart(eventId: number, wineId: number, wineCartSellingInfoUpdate?: WineCartSellingInfoUpdate, options?: any) {
        return MarketApiFp(this.configuration).updateWineInEventCart(eventId, wineId, wineCartSellingInfoUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete profile of the user by email address.
         * @summary Delete profile
         * @param {ProfileDelete} profileDelete User credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (profileDelete: ProfileDelete, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileDelete' is not null or undefined
            assertParamExists('deleteProfile', 'profileDelete', profileDelete)
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileDelete, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get profile information of the currently logged in user.
         * @summary Get profile information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Try to authenticate user by username and password pair. In case of successful authentication, the server will set a cookie with a session identifier. This cookie will be used for all subsequent requests to the server.
         * @summary Login
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('login', 'inlineObject', inlineObject)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Try to logout user. In case of successful logout, the server will remove the session identifier from the cookie and destroy the session.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new user. The user will be registered with the `authenticated user` role. The user will be logged in automatically after successful registration. 
         * @summary Register
         * @param {ProfileRegistration} profileRegistration User credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (profileRegistration: ProfileRegistration, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileRegistration' is not null or undefined
            assertParamExists('register', 'profileRegistration', profileRegistration)
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileRegistration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update profile information of the currently logged in user.
         * @summary Update profile information
         * @param {ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword} profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword Profile information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword: ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword' is not null or undefined
            assertParamExists('updateProfile', 'profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword', profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword)
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete profile of the user by email address.
         * @summary Delete profile
         * @param {ProfileDelete} profileDelete User credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(profileDelete: ProfileDelete, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProfile(profileDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get profile information of the currently logged in user.
         * @summary Get profile information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Try to authenticate user by username and password pair. In case of successful authentication, the server will set a cookie with a session identifier. This cookie will be used for all subsequent requests to the server.
         * @summary Login
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Try to logout user. In case of successful logout, the server will remove the session identifier from the cookie and destroy the session.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register a new user. The user will be registered with the `authenticated user` role. The user will be logged in automatically after successful registration. 
         * @summary Register
         * @param {ProfileRegistration} profileRegistration User credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(profileRegistration: ProfileRegistration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(profileRegistration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update profile information of the currently logged in user.
         * @summary Update profile information
         * @param {ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword} profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword Profile information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword: ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * Delete profile of the user by email address.
         * @summary Delete profile
         * @param {ProfileDelete} profileDelete User credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(profileDelete: ProfileDelete, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProfile(profileDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * Get profile information of the currently logged in user.
         * @summary Get profile information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<Profile> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Try to authenticate user by username and password pair. In case of successful authentication, the server will set a cookie with a session identifier. This cookie will be used for all subsequent requests to the server.
         * @summary Login
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inlineObject: InlineObject, options?: any): AxiosPromise<Profile> {
            return localVarFp.login(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Try to logout user. In case of successful logout, the server will remove the session identifier from the cookie and destroy the session.
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new user. The user will be registered with the `authenticated user` role. The user will be logged in automatically after successful registration. 
         * @summary Register
         * @param {ProfileRegistration} profileRegistration User credentials.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(profileRegistration: ProfileRegistration, options?: any): AxiosPromise<Profile> {
            return localVarFp.register(profileRegistration, options).then((request) => request(axios, basePath));
        },
        /**
         * Update profile information of the currently logged in user.
         * @summary Update profile information
         * @param {ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword} profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword Profile information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword: ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateProfile(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * Delete profile of the user by email address.
     * @summary Delete profile
     * @param {ProfileDelete} profileDelete User credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public deleteProfile(profileDelete: ProfileDelete, options?: any) {
        return ProfileApiFp(this.configuration).deleteProfile(profileDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get profile information of the currently logged in user.
     * @summary Get profile information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: any) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Try to authenticate user by username and password pair. In case of successful authentication, the server will set a cookie with a session identifier. This cookie will be used for all subsequent requests to the server.
     * @summary Login
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public login(inlineObject: InlineObject, options?: any) {
        return ProfileApiFp(this.configuration).login(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Try to logout user. In case of successful logout, the server will remove the session identifier from the cookie and destroy the session.
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public logout(options?: any) {
        return ProfileApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new user. The user will be registered with the `authenticated user` role. The user will be logged in automatically after successful registration. 
     * @summary Register
     * @param {ProfileRegistration} profileRegistration User credentials.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public register(profileRegistration: ProfileRegistration, options?: any) {
        return ProfileApiFp(this.configuration).register(profileRegistration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update profile information of the currently logged in user.
     * @summary Update profile information
     * @param {ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword} profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword Profile information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfile(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword: ProfileUpdateDisplayName | ProfileUpdateUsername | ProfileUpdatePassword, options?: any) {
        return ProfileApiFp(this.configuration).updateProfile(profileUpdateDisplayNameProfileUpdateUsernameProfileUpdatePassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get exceptions of the collection with the specified ID. The exceptions are paginated. The page can be specified by offset and limit parameters. 
         * @summary Get collection exceptions
         * @param {number} collectionId An identifier of the collection.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionExceptions: async (collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('getCollectionExceptions', 'collectionId', collectionId)
            const localVarPath = `/collection/{collection_id}/exceptions`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get history of the collection with the specified ID. The history is paginated. The page can be specified by offset and limit parameters. 
         * @summary Get collection history
         * @param {number} collectionId An identifier of the collection.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionHistory: async (collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('getCollectionHistory', 'collectionId', collectionId)
            const localVarPath = `/collection/{collection_id}/history`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (groupBy !== undefined) {
                localVarQueryParameter['group_by'] = groupBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sensor measurements in storage where collection with the specified ID is situated. 
         * @summary Get collection measurements
         * @param {number} collectionId An identifier of the collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionMeasurements: async (collectionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('getCollectionMeasurements', 'collectionId', collectionId)
            const localVarPath = `/collection/{collection_id}/measurements`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get detailed information about the wine with the specified ID and preloaded page of collections owned by the user. The page of collections can be specified by offset and limit parameters. 
         * @summary Get collection wine
         * @param {number} wineId An identifier of the wine.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionWine: async (wineId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('getCollectionWine', 'wineId', wineId)
            const localVarPath = `/wine/{wine_id}`
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of collections. 
         * @summary Get list of collections
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'purchase_date' | 'year' | 'price'} [sort] Specifies a field on which to sort the response.
         * @param {'asc' | 'desc'} [order] Specifies that the values in the specified field should be sorted in ascending or descending order. Takes effect only if _sort_ parameter defined.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollections: async (offset?: number, limit?: number, sort?: 'purchase_date' | 'year' | 'price', order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/collection/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of collections which contain the wine with the specified ID. 
         * @summary Get collections by wine
         * @param {number} wineId An identifier of the wine.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollectionsByWine: async (wineId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'wineId' is not null or undefined
            assertParamExists('listCollectionsByWine', 'wineId', wineId)
            const localVarPath = `/wine/{wine_id}/collection/list`
                .replace(`{${"wine_id"}}`, encodeURIComponent(String(wineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Order delivery of the collection with the specified ID. 
         * @summary Order collection delivery
         * @param {number} collectionId An identifier of the collection.
         * @param {CollectionDeliveryOrder} [collectionDeliveryOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCollectionDelivery: async (collectionId: number, collectionDeliveryOrder?: CollectionDeliveryOrder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('orderCollectionDelivery', 'collectionId', collectionId)
            const localVarPath = `/collection/{collection_id}/delivery`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Web required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeliveryOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * Get exceptions of the collection with the specified ID. The exceptions are paginated. The page can be specified by offset and limit parameters. 
         * @summary Get collection exceptions
         * @param {number} collectionId An identifier of the collection.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionExceptions(collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionExceptionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionExceptions(collectionId, offset, limit, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get history of the collection with the specified ID. The history is paginated. The page can be specified by offset and limit parameters. 
         * @summary Get collection history
         * @param {number} collectionId An identifier of the collection.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionHistory(collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionHistory(collectionId, offset, limit, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get sensor measurements in storage where collection with the specified ID is situated. 
         * @summary Get collection measurements
         * @param {number} collectionId An identifier of the collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionMeasurements(collectionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionMeasurements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionMeasurements(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get detailed information about the wine with the specified ID and preloaded page of collections owned by the user. The page of collections can be specified by offset and limit parameters. 
         * @summary Get collection wine
         * @param {number} wineId An identifier of the wine.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionWine(wineId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineInCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionWine(wineId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of collections. 
         * @summary Get list of collections
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'purchase_date' | 'year' | 'price'} [sort] Specifies a field on which to sort the response.
         * @param {'asc' | 'desc'} [order] Specifies that the values in the specified field should be sorted in ascending or descending order. Takes effect only if _sort_ parameter defined.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCollections(offset?: number, limit?: number, sort?: 'purchase_date' | 'year' | 'price', order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionWithWineList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCollections(offset, limit, sort, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of collections which contain the wine with the specified ID. 
         * @summary Get collections by wine
         * @param {number} wineId An identifier of the wine.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCollectionsByWine(wineId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCollectionsByWine(wineId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Order delivery of the collection with the specified ID. 
         * @summary Order collection delivery
         * @param {number} collectionId An identifier of the collection.
         * @param {CollectionDeliveryOrder} [collectionDeliveryOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderCollectionDelivery(collectionId: number, collectionDeliveryOrder?: CollectionDeliveryOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderCollectionDelivery(collectionId, collectionDeliveryOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * Get exceptions of the collection with the specified ID. The exceptions are paginated. The page can be specified by offset and limit parameters. 
         * @summary Get collection exceptions
         * @param {number} collectionId An identifier of the collection.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionExceptions(collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options?: any): AxiosPromise<CollectionExceptionList> {
            return localVarFp.getCollectionExceptions(collectionId, offset, limit, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get history of the collection with the specified ID. The history is paginated. The page can be specified by offset and limit parameters. 
         * @summary Get collection history
         * @param {number} collectionId An identifier of the collection.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionHistory(collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options?: any): AxiosPromise<CollectionHistoryList> {
            return localVarFp.getCollectionHistory(collectionId, offset, limit, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sensor measurements in storage where collection with the specified ID is situated. 
         * @summary Get collection measurements
         * @param {number} collectionId An identifier of the collection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionMeasurements(collectionId: number, options?: any): AxiosPromise<CollectionMeasurements> {
            return localVarFp.getCollectionMeasurements(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get detailed information about the wine with the specified ID and preloaded page of collections owned by the user. The page of collections can be specified by offset and limit parameters. 
         * @summary Get collection wine
         * @param {number} wineId An identifier of the wine.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionWine(wineId: number, offset?: number, limit?: number, options?: any): AxiosPromise<WineInCollection> {
            return localVarFp.getCollectionWine(wineId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of collections. 
         * @summary Get list of collections
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {'purchase_date' | 'year' | 'price'} [sort] Specifies a field on which to sort the response.
         * @param {'asc' | 'desc'} [order] Specifies that the values in the specified field should be sorted in ascending or descending order. Takes effect only if _sort_ parameter defined.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollections(offset?: number, limit?: number, sort?: 'purchase_date' | 'year' | 'price', order?: 'asc' | 'desc', options?: any): AxiosPromise<CollectionWithWineList> {
            return localVarFp.listCollections(offset, limit, sort, order, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of collections which contain the wine with the specified ID. 
         * @summary Get collections by wine
         * @param {number} wineId An identifier of the wine.
         * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
         * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCollectionsByWine(wineId: number, offset?: number, limit?: number, options?: any): AxiosPromise<CollectionList> {
            return localVarFp.listCollectionsByWine(wineId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Order delivery of the collection with the specified ID. 
         * @summary Order collection delivery
         * @param {number} collectionId An identifier of the collection.
         * @param {CollectionDeliveryOrder} [collectionDeliveryOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderCollectionDelivery(collectionId: number, collectionDeliveryOrder?: CollectionDeliveryOrder, options?: any): AxiosPromise<void> {
            return localVarFp.orderCollectionDelivery(collectionId, collectionDeliveryOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * Get exceptions of the collection with the specified ID. The exceptions are paginated. The page can be specified by offset and limit parameters. 
     * @summary Get collection exceptions
     * @param {number} collectionId An identifier of the collection.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getCollectionExceptions(collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options?: any) {
        return StorageApiFp(this.configuration).getCollectionExceptions(collectionId, offset, limit, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get history of the collection with the specified ID. The history is paginated. The page can be specified by offset and limit parameters. 
     * @summary Get collection history
     * @param {number} collectionId An identifier of the collection.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {'day' | 'month'} [groupBy] Specifies a period of time to group the measurement history entries by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getCollectionHistory(collectionId: number, offset?: number, limit?: number, groupBy?: 'day' | 'month', options?: any) {
        return StorageApiFp(this.configuration).getCollectionHistory(collectionId, offset, limit, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sensor measurements in storage where collection with the specified ID is situated. 
     * @summary Get collection measurements
     * @param {number} collectionId An identifier of the collection.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getCollectionMeasurements(collectionId: number, options?: any) {
        return StorageApiFp(this.configuration).getCollectionMeasurements(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get detailed information about the wine with the specified ID and preloaded page of collections owned by the user. The page of collections can be specified by offset and limit parameters. 
     * @summary Get collection wine
     * @param {number} wineId An identifier of the wine.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getCollectionWine(wineId: number, offset?: number, limit?: number, options?: any) {
        return StorageApiFp(this.configuration).getCollectionWine(wineId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of collections. 
     * @summary Get list of collections
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {'purchase_date' | 'year' | 'price'} [sort] Specifies a field on which to sort the response.
     * @param {'asc' | 'desc'} [order] Specifies that the values in the specified field should be sorted in ascending or descending order. Takes effect only if _sort_ parameter defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public listCollections(offset?: number, limit?: number, sort?: 'purchase_date' | 'year' | 'price', order?: 'asc' | 'desc', options?: any) {
        return StorageApiFp(this.configuration).listCollections(offset, limit, sort, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of collections which contain the wine with the specified ID. 
     * @summary Get collections by wine
     * @param {number} wineId An identifier of the wine.
     * @param {number} [offset] Specifies the number of objects to skip before server starts to return objects from the request query.
     * @param {number} [limit] Limits the objects returned in a response to a specified number of objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public listCollectionsByWine(wineId: number, offset?: number, limit?: number, options?: any) {
        return StorageApiFp(this.configuration).listCollectionsByWine(wineId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Order delivery of the collection with the specified ID. 
     * @summary Order collection delivery
     * @param {number} collectionId An identifier of the collection.
     * @param {CollectionDeliveryOrder} [collectionDeliveryOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public orderCollectionDelivery(collectionId: number, collectionDeliveryOrder?: CollectionDeliveryOrder, options?: any) {
        return StorageApiFp(this.configuration).orderCollectionDelivery(collectionId, collectionDeliveryOrder, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxonomyApi - axios parameter creator
 * @export
 */
export const TaxonomyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all taxonomy terms by the specified language. It is recommended to create dictionary of taxonomy terms on the client side and store it in the local storage. This will reduce the number of requests to the server and improve the performance of the application. For the optimization purposes, the server does not return the taxonomy terms which are not used. 
         * @summary Get taxonomy terms
         * @param {'ru' | 'ro' | 'en'} language A language code to translate taxonomy terms.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyGet: async (language: 'ru' | 'ro' | 'en', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('taxonomyGet', 'language', language)
            const localVarPath = `/taxonomy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxonomyApi - functional programming interface
 * @export
 */
export const TaxonomyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxonomyApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all taxonomy terms by the specified language. It is recommended to create dictionary of taxonomy terms on the client side and store it in the local storage. This will reduce the number of requests to the server and improve the performance of the application. For the optimization purposes, the server does not return the taxonomy terms which are not used. 
         * @summary Get taxonomy terms
         * @param {'ru' | 'ro' | 'en'} language A language code to translate taxonomy terms.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxonomyGet(language: 'ru' | 'ro' | 'en', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaxonomyTerm>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxonomyGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxonomyApi - factory interface
 * @export
 */
export const TaxonomyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxonomyApiFp(configuration)
    return {
        /**
         * Get all taxonomy terms by the specified language. It is recommended to create dictionary of taxonomy terms on the client side and store it in the local storage. This will reduce the number of requests to the server and improve the performance of the application. For the optimization purposes, the server does not return the taxonomy terms which are not used. 
         * @summary Get taxonomy terms
         * @param {'ru' | 'ro' | 'en'} language A language code to translate taxonomy terms.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxonomyGet(language: 'ru' | 'ro' | 'en', options?: any): AxiosPromise<Array<TaxonomyTerm>> {
            return localVarFp.taxonomyGet(language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxonomyApi - object-oriented interface
 * @export
 * @class TaxonomyApi
 * @extends {BaseAPI}
 */
export class TaxonomyApi extends BaseAPI {
    /**
     * Get all taxonomy terms by the specified language. It is recommended to create dictionary of taxonomy terms on the client side and store it in the local storage. This will reduce the number of requests to the server and improve the performance of the application. For the optimization purposes, the server does not return the taxonomy terms which are not used. 
     * @summary Get taxonomy terms
     * @param {'ru' | 'ro' | 'en'} language A language code to translate taxonomy terms.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public taxonomyGet(language: 'ru' | 'ro' | 'en', options?: any) {
        return TaxonomyApiFp(this.configuration).taxonomyGet(language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackingApi - axios parameter creator
 * @export
 */
export const TrackingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get boundaries of conditions in which wine can be safety stored. 
         * @summary Get tracking boundaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackingBoundaries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tracking/boundaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingApi - functional programming interface
 * @export
 */
export const TrackingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get boundaries of conditions in which wine can be safety stored. 
         * @summary Get tracking boundaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrackingBoundaries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingBoundaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrackingBoundaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackingApi - factory interface
 * @export
 */
export const TrackingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingApiFp(configuration)
    return {
        /**
         * Get boundaries of conditions in which wine can be safety stored. 
         * @summary Get tracking boundaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrackingBoundaries(options?: any): AxiosPromise<TrackingBoundaries> {
            return localVarFp.getTrackingBoundaries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingApi - object-oriented interface
 * @export
 * @class TrackingApi
 * @extends {BaseAPI}
 */
export class TrackingApi extends BaseAPI {
    /**
     * Get boundaries of conditions in which wine can be safety stored. 
     * @summary Get tracking boundaries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public getTrackingBoundaries(options?: any) {
        return TrackingApiFp(this.configuration).getTrackingBoundaries(options).then((request) => request(this.axios, this.basePath));
    }
}


