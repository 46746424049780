import { PropsWithChildren } from "react";
import styles from "./Main.module.scss";

type MainProps = PropsWithChildren<{
  className?: string;
}>
const Main = ({ className, children }: MainProps) => {

  return (
    <main className={`${styles.main} ${className ?? ""}`}>
      {children}
    </main>
  );
};

export default Main;