import React, { PropsWithChildren } from "react";
import styles from "./Page.module.scss";

type PageProps = PropsWithChildren<{
  header?: React.JSX.Element;
  footer?: React.JSX.Element;
}>;

const Page = ({ children, header, footer }: PageProps) => {

  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageContent}>
        {header !== undefined && (
          header
        )}
        {children}
        {footer !== undefined && (
          footer
        )}
      </div>
    </div>
  );
};

export default Page;