import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Direction, HorizontalAlign, VerticalAlign } from "..";
import styles from "./FlexContainer.module.scss";

export type FlexContainerProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  direction?: Direction,
  verticalAlign?: VerticalAlign,
  horizontalAlign?: HorizontalAlign,
  gap?: number | string,

  /**
   * @deprecated Use classes instead.
   */
  width?: number | string,

  /**
   * @deprecated Use classes instead.
   */
  height?: number | string,
  spaceBetween?: boolean,
  wrap?: boolean,
};

const FlexContainer: FunctionComponent<FlexContainerProps> = ({ className, children, direction = Direction.Column, gap, height, horizontalAlign, spaceBetween, style, verticalAlign, width, wrap, ...props }: FlexContainerProps) => {

  if (process.env.NODE_ENV === "development" && spaceBetween) {
    if (direction === Direction.Column && verticalAlign !== undefined) {
      console.warn("The \"spaceBetween\" property is set, so the \"verticalAlign\" one is ignored.");
    }
    else if (direction === Direction.Row && horizontalAlign !== undefined) {
      console.warn("The \"spaceBetween\" property is set, so the \"horizontalAlign\" one is ignored.");
    }
  }

  return (
    <div
      className={`${styles.flexContainer} ${styles[direction]} ${styles[horizontalAlign ?? HorizontalAlign.Center]} ${styles[verticalAlign ?? VerticalAlign.Middle]} ${spaceBetween ? styles.spaceBetween : ""} ${wrap ? styles.wrap : ""} ${className ?? ""}`}
      style={(gap || width || height) ?
        Object.assign({
          gap: typeof gap === "number" ? `${gap}px` : gap,
          width: typeof width === "number" ? `${width}px` : width,
          height: typeof height === "number" ? `${height}px` : height,
          flexShrink: width ? 0 : undefined,
        }, style) :
        style
      }
      {...props}
    >
      {children}
    </div>
  );
};

export default FlexContainer;
