import React, { DetailedHTMLProps, ForwardedRef, forwardRef, HTMLAttributes } from "react";
import FlexContainer from "./FlexContainer";
import { Theme } from "./index";
import {ReactComponent as CloseIcon} from "../img/close.svg";
import styles from "./Modal.module.scss";

type ModalRef = ForwardedRef<HTMLDivElement>;

type TPopupProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  onBackdropClick?: () => void,
  theme?: Theme,
  className?: string,
  backdropTransparent?: boolean,
};

const Modal = ({ children, className, backdropTransparent, onBackdropClick, theme }: TPopupProps, ref: ModalRef) => {

  return (
    <FlexContainer className={`${styles.modal} ${styles[theme ?? Theme.Blue]} ${backdropTransparent ? styles.backdropTransparent : ""}`} onClick={onBackdropClick ?? undefined} ref={ref}>
      <div className={`${styles.modalBody} ${className ?? ""}`} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}>
        {onBackdropClick && (
          <CloseIcon className={styles.close} onClick={onBackdropClick}/>
        )}
        <div className={styles.modalBodyContent}>
          {children}
        </div>
      </div>
    </FlexContainer>
  );
};

export default forwardRef(Modal);