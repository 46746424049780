// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GridContainer_gridContainer__BDRQs{display:grid;width:100%}.GridContainer_gridContainer__BDRQs.GridContainer_column__Rnd\\+r{grid-auto-flow:column}.GridContainer_gridContainer__BDRQs.GridContainer_row__YT15T{grid-auto-flow:row}.GridContainer_gridContainer__BDRQs.GridContainer_left__zbAFb{justify-items:flex-start}.GridContainer_gridContainer__BDRQs.GridContainer_center__p4EXx{justify-items:center}.GridContainer_gridContainer__BDRQs.GridContainer_right__cxWda{justify-items:flex-end}.GridContainer_gridContainer__BDRQs.GridContainer_top__ziFJ4{align-items:flex-start}.GridContainer_gridContainer__BDRQs.GridContainer_middle__5B\\+Mu{align-items:center}.GridContainer_gridContainer__BDRQs.GridContainer_bottom__kIchG{align-items:flex-end}", "",{"version":3,"sources":["webpack://./src/components/GridContainer.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,UAAA,CAEA,iEACE,qBAAA,CAGF,6DACE,kBAAA,CAGF,8DACE,wBAAA,CAGF,gEACE,oBAAA,CAGF,+DACE,sBAAA,CAGF,6DACE,sBAAA,CAGF,iEACE,kBAAA,CAGF,gEACE,oBAAA","sourcesContent":[".gridContainer {\n  display: grid;\n  width: 100%;\n\n  &.column {\n    grid-auto-flow: column;\n  }\n\n  &.row {\n    grid-auto-flow: row;\n  }\n\n  &.left {\n    justify-items: flex-start;\n  }\n\n  &.center {\n    justify-items: center;\n  }\n\n  &.right {\n    justify-items: flex-end;\n  }\n\n  &.top {\n    align-items: flex-start;\n  }\n\n  &.middle {\n    align-items: center;\n  }\n\n  &.bottom {\n    align-items: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": "GridContainer_gridContainer__BDRQs",
	"column": "GridContainer_column__Rnd+r",
	"row": "GridContainer_row__YT15T",
	"left": "GridContainer_left__zbAFb",
	"center": "GridContainer_center__p4EXx",
	"right": "GridContainer_right__cxWda",
	"top": "GridContainer_top__ziFJ4",
	"middle": "GridContainer_middle__5B+Mu",
	"bottom": "GridContainer_bottom__kIchG"
};
export default ___CSS_LOADER_EXPORT___;
