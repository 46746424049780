import { PropsWithChildren } from "react";
import styles from "./SimpleText.module.scss";

type TProps = PropsWithChildren<{
  className?: string
}>;

const SimpleText = ({children, className}:TProps) => {
  return (
    <span className={`${styles.simpleText} ${className ?? ""}`}>
      {children}
    </span>
  )
}

export default SimpleText;