import i18n from "i18next";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { TaxonomyApiFactory } from "../../services/api/v1";
import Context, { TTaxonomyProps } from "./Context";

type Props = PropsWithChildren<{}>;

export default function Provider({ children }: Props) {
  const [taxonomy, setTaxonomy] = useState<TTaxonomyProps[]>(localStorage.getItem("taxonomy") ? JSON.parse(localStorage.getItem("taxonomy") as string) : []);

  useEffect(() => {
    if (!localStorage.getItem("taxonomy")) {
      const currentLanguage = i18n.language as "en" | "ru" | "ro";
      TaxonomyApiFactory().taxonomyGet(currentLanguage).then((response) => {
        localStorage.setItem("taxonomy", JSON.stringify(response.data));
        setTaxonomy(response.data);
      }).catch(e => {
        //
      });
    }
  }, []);

  return (
    <Context.Provider value={{
      taxonomy: taxonomy,
      setTaxonomy: (item: TTaxonomyProps) => {
        const newTaxonomy = [...taxonomy];
        newTaxonomy.push(item);
        setTaxonomy(newTaxonomy);
      },
      translate: (tid: number | number[]) => {
        if (typeof tid === "number") {
          const item = taxonomy.find(item => item.id === tid);
          return item ? item.name : "";
        } else {
          return tid.map(tid => {
            const item = taxonomy.find(item => item.id === tid);
            return item ? item.name : "";
          }).join(" ");
        }
      },
    }}>
      {children}
    </Context.Provider>
  );
}

