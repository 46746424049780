import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/**
 * Namespaces of i18next.
 */
export enum I18Namespace {
  Main = "main",
}

export enum Language {
  Russian = "ru",
  English = "en",
  Romanian = "ro",
}

function getLanguage() {
  return localStorage.getItem("language") !== null ? localStorage.getItem("language") as string : Language.English;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    },
    lng: getLanguage(),
    fallbackLng: Language.English,
    debug: (process.env.NODE_ENV || "development") === "development",
    interpolation: {
      // Not needed for React as it escapes by default.
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
      useSuspense: true,
    },
    defaultNS: I18Namespace.Main,
    ns: [I18Namespace.Main],
    fallbackNS: I18Namespace.Main,
    load: "currentOnly",
  });

