import React from "react";
import { To, useNavigate } from "react-router-dom";
import {ReactComponent as ArrowLeft} from "../assets/images/arrow_left.svg";
import styles from "./Back.module.scss";

type TBackProps = {
  title: string|React.ReactElement|null,
  to: string|number,
  className?: string
}

const Back = ({title, to, className}: TBackProps) => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.back} ${className ?? ""}`} onClick={() => navigate(to as To)}>
      <ArrowLeft /> {title}
    </div>
  );
}

export default Back;