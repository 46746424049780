// Components will be added later.
// This file should export all custom React components.
import Button, { ButtonProps, ButtonIconPosition, ButtonAppearance } from "./Button";
import Checkbox, { CheckboxProps } from "./Checkbox";
import FlexContainer, { FlexContainerProps } from "./FlexContainer";
import GridContainer, { GridContainerProps } from "./GridContainer";
import Heading1 from "./Heading1";
import Heading2 from "./Heading2";
import Heading3 from "./Heading3";
import Heading4 from "./Heading4";
import LabeledCheckbox, { LabeledCheckboxProps } from "./LabeledCheckbox";
import Modal from "./Modal";
import Tabs, { Tab } from "./Tabs";
import TextAlignWrapper, { TextAlign } from "./TextAlignWrapper";
import Textbox, { TextboxAppearance } from "./Textbox";

export enum Theme {
  Light = "light",
  Blue = "blue",
  Gray = "gray",
  Green = "green",
  White = "white",
  GrayWhite = "grayWhite",
  Dark = "dark",
  Red = "red",
  DarkBlue = "darkBlue"
}

enum Direction {
  Column = "column",
  Row = "row",
}

enum VerticalAlign {
  Top = "top",
  Middle = "middle",
  Bottom = "bottom",
}

enum HorizontalAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

export {
  TextAlign,
  Button,
  ButtonProps,
  ButtonIconPosition,
  ButtonAppearance,
  Heading1,
  Textbox,
  TextboxAppearance,
  Tabs,
  Tab,
  Modal,
  Heading2,
  Heading3,
  Heading4,
  TextAlignWrapper,
  Direction,
  VerticalAlign,
  HorizontalAlign,
  FlexContainer,
  FlexContainerProps,
  GridContainer,
  GridContainerProps,
  Checkbox,
  CheckboxProps,
  LabeledCheckbox,
  LabeledCheckboxProps
};