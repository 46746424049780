import Page from "./Page";

import "../assets/styles/index.scss";

const Preloader = () => {
  return (
    <Page>
      <main />
    </Page>
  )
};

export default Preloader;