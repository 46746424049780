import { createContext, ReactNode } from "react";
import { Theme } from "../../components";

const emptyFunction = () => {
  return;
};

export type TModalParams = {
  className?: string,
  onBackdropClick?: () => void,
  backdropTransparent?: boolean,
  theme?: Theme,
}

type Props = {
  showModal: (content?: ReactNode, params?: TModalParams) => void,
  closeModal: () => void,
}

export const initialState: Props = {
  showModal: emptyFunction,
  closeModal: emptyFunction,
};

export default createContext<Props>(initialState);
