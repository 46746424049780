// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextAlignWrapper_left__LZxkS{text-align:left}.TextAlignWrapper_center__MBcP9{text-align:center}.TextAlignWrapper_right__j2pmV{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/TextAlignWrapper.module.scss"],"names":[],"mappings":"AAKE,8BACE,eAAA,CADF,gCACE,iBAAA,CADF,+BACE,gBAAA","sourcesContent":["@use \"sass:string\";\n\n$text-aligns: \"left\", \"center\", \"right\";\n\n@each $textAlign in $text-aligns {\n  .#{$textAlign} {\n    text-align: string.unquote($textAlign);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"left": "TextAlignWrapper_left__LZxkS",
	"center": "TextAlignWrapper_center__MBcP9",
	"right": "TextAlignWrapper_right__j2pmV"
};
export default ___CSS_LOADER_EXPORT___;
