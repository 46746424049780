// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Heading3_heading3__JoseF{font-family:var(--font-main);font-weight:700;font-size:32px;line-height:48px;color:var(--heading-3-color-default)}.Heading3_heading3__JoseF.Heading3_blue__e\\+QwT{--heading-3-color-default: #242528}", "",{"version":3,"sources":["webpack://./src/components/Heading3.module.scss"],"names":[],"mappings":"AAEA,0BACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oCAAA,CAEA,gDACE,kCAAA","sourcesContent":["@use \"../styles/colors\";\n\n.heading3 {\n  font-family: var(--font-main);\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 48px;\n  color: var(--heading-3-color-default);\n\n  &.blue {\n    --heading-3-color-default: #{colors.$dark-gray};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading3": "Heading3_heading3__JoseF",
	"blue": "Heading3_blue__e+QwT"
};
export default ___CSS_LOADER_EXPORT___;
