import React from "react";
import { usePagination, DOTS } from "../hooks/usePagination";
import styles from "./Pagination.module.scss";

type TProps = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: 1 | undefined;
  currentPage: number;
  pageSize: number;
  className?: string;
  showNextPrev?: boolean;
}
const Pagination = (props: TProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 2,
    currentPage,
    pageSize,
    className,
    showNextPrev = false
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 0;
  return (
    <ul className={`${styles.pagination} ${className ?? ""}`}>
      {showNextPrev && (
        <li
          className={`${styles.item} ${currentPage === 1 ? styles.disabled : ""}`}
          onClick={onPrevious}
          role="link"
        >
          <div className="arrow left"/>
        </li>
      )}
      {paginationRange && paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className={`${styles.item} ${styles.dots}`}>&#8230;</li>;
        }

        return (
          <li
            className={`${styles.item} ${pageNumber === currentPage ? styles.selected : ""}`}
            onClick={() => pageNumber !== DOTS ? onPageChange(pageNumber as number) : undefined}
            role="link"
          >
            {pageNumber}
          </li>
        );
      })}
      {showNextPrev && (
        <li
          className={`${styles.item} ${currentPage === lastPage ? styles.disabled : ""}`}
          onClick={onNext}
          role="link"
        >
          <div className="arrow right"/>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
