import { PropsWithChildren, useEffect, useState } from "react";
import { ProfileApiFactory } from "../../services/api/v1";

import Context, { TProfile } from "./Context";

type Props = PropsWithChildren<{}>;

export default function Provider(props: Props) {

  const [profile, setProfile] = useState<TProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profile === null) {
      ProfileApiFactory().getProfile().then(response => {
        const data = response.data;
        if (typeof data === "object" && !data.hasOwnProperty("error")) {
          setProfile({
            first_name: data.first_name,
            last_name: data.last_name,
          });
        }
      }).finally(() => {
        setLoading(false);
      }).catch(error => {
        // Not logged in exception.
      });
    }
  });

  return (
    <Context.Provider
      value={{
        profile,
        setProfile: setProfile,
      }}>
      {!loading ? props.children : ""}
    </Context.Provider>);
}