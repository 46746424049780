import { GridContainer, VerticalAlign } from "@rizov/rizov-ui-react";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Back from "../components/Back";
import FadeIn from "../components/FadeIn";
import Main from "../components/Main";
import { TrackingProvider } from "../context/tracking";
import { I18Namespace } from "../i18n";
import WineCellarConditions from "../sections/WineCellarConditions";
import WineCellarWine from "../sections/WineCellarWine";
import WineCellarWines from "../sections/WineCellarWines";
import { StorageApiFactory, WineInCollection } from "../services/api/v1";
import styles from "./WineCellar.module.scss";

const WineCellar = () => {

  const limit = 10;
  const { wineId } = useParams();
  const _navigate = useNavigate();
  const navigate = useRef(_navigate);
  const [t] = useTranslation(I18Namespace.Main);
  const [wine, setWine] = useState<WineInCollection>();

  useEffect(() => {
    if (wineId) {
      StorageApiFactory().getCollectionWine(parseInt(wineId), undefined, limit).then((res) => {
        if (res.data.collections.total === 0) {
          navigate.current("/digital-storage");
        } else {
          setWine(res.data);
        }
      }).catch((error) => {
        if (error instanceof AxiosError) {
          const data = error.response?.data;
          if (data && data.hasOwnProperty("error")) {
            navigate.current("/digital-storage");
          }
        }
      });
    }
  }, [wineId]);

  useEffect(() => {
    navigate.current = _navigate;
  }, [_navigate]);

  return (
    <Main className={styles.wineCellar}>

      <Back title={t("DigitalStorage.backToStorage")}
        to={"/digital-storage"}
        className="ml-30" />

      {wine && (
        <FadeIn>
          <GridContainer
            className={`${styles.wineData} centered-content mt-48 mt-0--992`}
            verticalAlign={VerticalAlign.Top}
          >
            <WineCellarWine wine={wine} />
          </GridContainer>

          <div>
            <Routes>
              <Route path="/" element={<WineCellarWines wine={wine} />} />
              <Route path="/conditions/:collectionId" element={
                <TrackingProvider>
                  <WineCellarConditions />
                </TrackingProvider>
              } />
            </Routes>
          </div>
        </FadeIn>
      )}
    </Main>
  );
};

export default WineCellar;
