import React, { memo, PropsWithChildren, ReactElement, useMemo, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import styles from "./Tabs.module.scss";

type TabsProps<T> = {
  active?: T,
  className?: string,
  children: Array<ReactElement<TabProps<T>, typeof Tab>> | ReactElement<TabProps<T>, typeof Tab>,
};

type TabProps<T> = PropsWithChildren<{
  title: string;
} & ({
  onClick?: (activeTab: number) => void;
} | {
  id: T;
  onClick?: (activeTab: T) => void;
})>;

const Tabs = <T,>({ children, active, className }: TabsProps<T>) => {
  const tabs = useMemo((): TabProps<T>[] => {
    return React.Children.map(children, (child) => {
      return child.props;
    });
  }, [children]);

  const [activeTab, setActiveTab] = useState(() => {
    const index = tabs.findIndex((tab, index) => ("id" in tab ? tab.id : index) === active);
    if (index === -1) {
      return 0;
    }
    return index;
  });

  const activeTabContent = useMemo(() => {
    return tabs[activeTab].children;
  }, [tabs, activeTab]);

  return (
    <div className={`${styles.simpleTabs} ${className ?? ""}`}>
      <ul className={styles.navTabs}>
        {tabs.map((tab, idx) => (
          <li className={`${styles.navItem} ${idx === activeTab ? styles.active : ""}`} key={idx} onClick={() => {
            setActiveTab(idx);
            const _tab = tab;
            if (_tab.onClick !== undefined) {
              if ("id" in _tab) {
                _tab.onClick(_tab.id);
              }
              else {
                _tab.onClick(idx);
              }
            }
          }}>
            {tab.title}
          </li>
        ))}
      </ul>

      <SwitchTransition>
        <CSSTransition key={activeTab as React.Key} classNames="fade" timeout={300}>
          <div className={styles.tabContent}>
            {activeTabContent}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export const Tab = <T,>({ children }: TabProps<T>) => {
  return <>{children}</>;
};

export default memo(Tabs);