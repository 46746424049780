import { lazy, PropsWithChildren, Suspense, useContext } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./assets/styles/index.scss";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main";
import Page from "./components/Page";
import { AuthUserContext } from "./context/authUser";
import WineCellar from "./pages/WineCellar";

const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Tastings = lazy(() => import("./pages/Tastings"));
const Tasting = lazy(() => import("./pages/Tasting"));
const TastingResults = lazy(() => import("./pages/TastingResults"));
const TastingShop = lazy(() => import("./pages/TastingShop"));
const EventCart = lazy(() => import("./pages/EventCart"));
const Profile = lazy(() => import("./pages/Profile"));
const RegisterForm = lazy(() => import("./pages/RegisterForm"));
const DigitalStorage = lazy(() => import("./pages/DigitalStorage"));
const DigitalStorageCart = lazy(() => import("./pages/DigitalStorageCart"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const EventWinePaymentResult = lazy(() => import("./pages/EventWinePaymentResult"));
const SuspendAccount = lazy(() => import("./pages/SuspendAccount"));

type TRouteProps = PropsWithChildren<{
  redirectPath?: string,
}>

function App() {
  const location = useLocation();
  const authUser = useContext(AuthUserContext);

  const PrivateRoute = ({
    redirectPath = "/",
    children,
  }: TRouteProps) => {
    if (!authUser.profile) {
      return <Navigate to={redirectPath} replace />;
    }

    return children ? <>{children}</> : <Outlet />;
  };

  const GuestRoute = ({
    redirectPath = "/tastings",
    children,
  }: TRouteProps) => {
    if (!authUser.profile) {
      return children ? <>{children}</> : <Outlet />;
    }

    return <Navigate to={redirectPath} replace />;
  };

  return (
    <Page
      header={<Header />}
      footer={<Footer />}
    >
      <Suspense fallback={<Main />}>
        <SwitchTransition>
          <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
            <Routes location={location}>
              <Route path="/terms-and-conditions" element={<TermsConditions />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/account/:operation" element={<SuspendAccount />} />
              <Route element={<GuestRoute />}>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/register/form" element={<RegisterForm />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/tastings" element={<Tastings />} />
                <Route path="/tasting/:tastingId" element={<Tasting />}>
                  <Route path="winery/:wineryId" element={<Tasting />} />
                </Route>
                <Route path="/tasting/:tastingId/results" element={<TastingResults />} />
                <Route path="/tasting/:tastingId/shop" element={<TastingShop />} />
                <Route path="/tasting/:tastingId/cart" element={<EventCart />} />
                <Route path="/tasting/:tastingId/payment" element={<EventWinePaymentResult />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/digital-storage" element={<DigitalStorage />} />
                <Route path="/digital-storage/wine-cellar/:wineId" element={<WineCellar />} />
                <Route path="/digital-storage/wine-cellar/:wineId/cart" element={<DigitalStorageCart />} />
                <Route path="/digital-storage/wine-cellar/:wineId" element={<WineCellar />}>
                  <Route path="conditions/:collectionId" element={<WineCellar />} />
                </Route>
              </Route>
            </Routes>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </Page>
  );
}

export default App;
