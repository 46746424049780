// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LabeledCheckbox_labelCheckbox__HnZb0{display:flex;vertical-align:middle;font-weight:400;font-size:16px;line-height:24px;color:#242528;gap:12px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/LabeledCheckbox.module.scss"],"names":[],"mappings":"AAEA,sCACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,QAAA,CACA,cAAA","sourcesContent":["@use \"../styles/colors\";\n\n.labelCheckbox {\n  display: flex;\n  vertical-align: middle;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #{colors.$dark-gray};\n  gap: 12px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelCheckbox": "LabeledCheckbox_labelCheckbox__HnZb0"
};
export default ___CSS_LOADER_EXPORT___;
