import React, { PropsWithChildren, useEffect, useState } from "react";
import { TrackingApiFactory } from "../../services/api/v1";
import Context, { initialState, TBoundaries } from "./Context";

type Props = PropsWithChildren<{}>;

export default function Provider({ children }: Props) {
  const [boundaries, setBoundaries] = useState<TBoundaries | undefined>(initialState.boundaries);

  useEffect(() => {
    let headers = {};
    if (boundaries !== undefined) {
      headers = { "If-Modified-Since": boundaries.lastModified };
    }

    TrackingApiFactory().getTrackingBoundaries({
      headers: headers,
    }).then(response => {
      const data = {
        lastModified: response.headers["last-modified"],
        item: response.data,
      };

      setBoundaries(data);
      localStorage.setItem("trackingBoundaries", JSON.stringify(data));

    }).catch(err => {

    });
  }, [boundaries]);

  return (
    <Context.Provider value={{
      boundaries: boundaries,
    }}>
      {children}
    </Context.Provider>
  );
}