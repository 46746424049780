import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Theme } from "./index";
import TextAlignWrapper, { TextAlign } from "./TextAlignWrapper";
import styles from "./Heading4.module.scss";

export type Heading4Props = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  textAlign?: TextAlign,
  theme?: Theme,
};

const Heading4: FunctionComponent<Heading4Props> = ({ className, children, textAlign, theme, ...props }: Heading4Props) => {
  return (
    <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
      <h4 className={`${styles.heading4} ${styles[theme ?? Theme.Dark]} ${className ?? ""}`} {...props}>
        {children}
      </h4>
    </TextAlignWrapper>
  );
};

export default Heading4;
