import RedBottle from "../assets/images/wines/red.jpg";
import RoseBottle from "../assets/images/wines/rose.jpg";
import WhiteBottle from "../assets/images/wines/white.jpg";
import SparklingBottle from "../assets/images/wines/sparkling.jpg";

export const getWineImage = (tids: number[]): string => {
  if (tids.includes(7297)) {
    return SparklingBottle;
  } else if (tids.includes(7293)) {
    return RedBottle;
  } else if (tids.includes(7295)) {
    return WhiteBottle;
  } else if (tids.includes(7294)) {
    return RoseBottle;
  }
  return "";
};

export const isEmail = (email: string) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const clamp = (num:number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const updateUrlParams = (params: {}) => {
  const newUrl = new URL(document.location.href);

  if (Object.keys(params).length) {
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined) {
        newUrl.searchParams.delete(key);
        return;
      }
      newUrl.searchParams.set(key, value as string);
    });
  }

  window.history.replaceState(null, "", newUrl.toString());
};