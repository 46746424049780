import { Children, PropsWithChildren, cloneElement, isValidElement } from "react";
import styles from "./FadeIn.module.scss";

type FadeInProps = PropsWithChildren;

const FadeIn = ({ children }: FadeInProps) => {
  return (
    <>
      {Children.map(children, (child) => isValidElement<any>(child) ? cloneElement(child, { className: `${child.props.className ?? ""} ${styles.fadeIn}` }) : child)}
    </>
  );
};

export default FadeIn;
