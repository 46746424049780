import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { I18Namespace } from "../i18n";
import styles from "./Footer.module.scss";
import { ReactComponent as EmailIcon } from "../assets/images/contacts/email.svg";
import { ReactComponent as FacebookIcon } from "../assets/images/contacts/facebook.svg";
import { Direction, FlexContainer } from "@rizov/rizov-ui-react";

const Footer = () => {
  const [t] = useTranslation(I18Namespace.Main);

  return (
    <footer className={styles.pageFooter}>

      <ul className={styles.contactUs}>
        <li className={styles.item}>
          <a href="mailto: risest@mail.ru" target={"_blank"} rel="noreferrer">
            <EmailIcon/>
          </a>
        </li>
        <li className={styles.item}>
          <a href="https://www.facebook.com/rizov.winery/" target={"_blank"} rel="noreferrer">
            <FacebookIcon/>
          </a>
        </li>
      </ul>

      <FlexContainer gap={40} direction={Direction.Row} className={styles.shortNav}>
        <Link to={"/terms-and-conditions"} className={styles.navLink}>
          {t("Footer.termsConditions")}
        </Link>
        <Link to={"/about-us"} className={styles.navLink}>
          {t("Footer.aboutUs")}
        </Link>
      </FlexContainer>

    </footer>
  );
};
export default Footer;
