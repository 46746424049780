import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Theme } from "./index";
import TextAlignWrapper, { TextAlign } from "./TextAlignWrapper";
import styles from "./Heading3.module.scss";

export type Heading3Props = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  textAlign?: TextAlign,
  theme?: Theme,
};

const Heading3: FunctionComponent<Heading3Props> = ({ className, children, textAlign, theme, ...props }: Heading3Props) => {
  return (
    <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
      <h3 className={`${styles.heading3} ${styles[theme ?? Theme.Dark]} ${className ?? ""}`} {...props}>
        {children}
      </h3>
    </TextAlignWrapper>
  );
};

export default Heading3;
