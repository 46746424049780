// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Heading2_heading2__2sNA0{font-family:var(--font-main);font-weight:700;font-size:40px;line-height:48px;color:var(--heading-2-color-default)}.Heading2_heading2__2sNA0.Heading2_blue__J\\+Zbk{--heading-2-color-default: #242528}", "",{"version":3,"sources":["webpack://./src/components/Heading2.module.scss"],"names":[],"mappings":"AAEA,0BACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oCAAA,CAEA,gDACE,kCAAA","sourcesContent":["@use \"../styles/colors\";\n\n.heading2 {\n  font-family: var(--font-main);\n  font-weight: 700;\n  font-size: 40px;\n  line-height: 48px;\n  color: var(--heading-2-color-default);\n\n  &.blue {\n    --heading-2-color-default: #{colors.$dark-gray};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading2": "Heading2_heading2__2sNA0",
	"blue": "Heading2_blue__J+Zbk"
};
export default ___CSS_LOADER_EXPORT___;
