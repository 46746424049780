import { createContext } from "react";
import { TrackingBoundaries } from "../../services/api/v1";

export type TBoundaries = {
  lastModified: string;
  item: TrackingBoundaries;
}

type Props = {
  boundaries?: TBoundaries,
}

export const initialState: Props = {
  boundaries: localStorage.getItem("trackingBoundaries") && localStorage.getItem("trackingBoundaries") != null ? JSON.parse(localStorage.getItem("trackingBoundaries") as string) : undefined,
};

export default createContext<Props>(initialState);
