// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Heading4_heading4__EkWPQ{font-family:var(--font-main);font-weight:700;font-size:24px;line-height:48px;color:var(--heading-4-color-default)}.Heading4_heading4__EkWPQ.Heading4_blue__bh8TA{--heading-4-color-default: #242528}", "",{"version":3,"sources":["webpack://./src/components/Heading4.module.scss"],"names":[],"mappings":"AAEA,0BACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oCAAA,CAEA,+CACE,kCAAA","sourcesContent":["@use \"../styles/colors\";\n\n.heading4 {\n  font-family: var(--font-main);\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 48px;\n  color: var(--heading-4-color-default);\n\n  &.blue {\n    --heading-4-color-default: #{colors.$dark-gray};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading4": "Heading4_heading4__EkWPQ",
	"blue": "Heading4_blue__bh8TA"
};
export default ___CSS_LOADER_EXPORT___;
