import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from "react";

type TProps = PropsWithChildren<{
  dataLength: number,
  next: () => void,
  hasMore: boolean,
  loader: ReactNode,
  endMessage?: ReactNode,
}>;
const InfiniteScroll = ({ dataLength, next, hasMore, loader, endMessage, children }: TProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsLoading(false);
  }, [dataLength]);

  const callFetchData = useCallback(() => {
    if (hasMore && !isLoading) {
      setIsLoading(true);
      next();
    }
  }, [hasMore, isLoading, next]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async entries => {
        if (entries[0].isIntersecting) {
          callFetchData();
        }
      },
      { threshold: 1 }
    );

    const current = observerTarget.current;
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [callFetchData]);

  return (
    <>
      {children}
      {isLoading && loader}
      {!hasMore && endMessage}
      <div ref={observerTarget} />
    </>
  )
}

export default InfiniteScroll;