import { Button, ButtonAppearance, ButtonIconPosition, Direction, FlexContainer, Heading2, HorizontalAlign, ModalContext, Textbox, Theme } from "@rizov/rizov-ui-react";
import { AxiosError } from "axios";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ReactComponent as DeliveryIcon } from "../assets/images/delivery.svg";
import { ReactComponent as RenewalIcon } from "../assets/images/renewal.svg";
import ErrorModalContent from "../components/ErrorModalContent";
import Pagination from "../components/Pagination";
import SimpleText from "../components/SimpleText";
import StockOperations from "../components/StockOperations";
import SuccessModalContent from "../components/SuccessModalContent";
import { AuthUserContext } from "../context/authUser";
import { I18Namespace } from "../i18n";
import styles from "../pages/WineCellar.module.scss";
import { Collection, CollectionList, StorageApiFactory, WineInCollection } from "../services/api/v1";
import { updateUrlParams } from "../utils/helpers";

type TStorageExtendModal = {
  collection: Collection;
  wine: WineInCollection;
  callbackOnFinish: () => void;
}

type TOrderDeliveryModal = {
  collection: Collection;
  wine: WineInCollection;
  callbackOnFinish: () => void;
}

const StorageExtendModal = ({ collection, wine, callbackOnFinish }: TStorageExtendModal) => {

  const [t] = useTranslation(I18Namespace.Main);
  const month_price = 1;
  const [storageExtending, setStorageExtending] = useState(1);

  const steps = {
    Quantity: 1,
    Result: 2
  };

  const [step, setStep] = useState(steps.Quantity);

  return (
    <FlexContainer className={`${styles.storageExtendModal} mt-12`}>
      <RenewalIcon className={styles.modalIcon} />
      <Heading2 className="mt-12 fs-26-36--992 text-center">{t("DigitalStorage.extendingStoragePeriod")}</Heading2>
      <SwitchTransition>
        <CSSTransition
          key={step}
          timeout={400}
          classNames="step-switch"
        >
          {(() => {
            switch (step) {
              case steps.Quantity:
                return (
                  <FlexContainer>
                    <SimpleText className="mt-24 mt-12--992 text-center">
                      {t("DigitalStorage.quantityBottlesOf", { quantity: collection.quantity, name:`${wine.name} ${wine.year}` })}
                    </SimpleText>
                    <SimpleText className="text-center">
                      {t("DigitalStorage.minimumExtensionPeriod")} - {t("DigitalStorage.countMonth", { count: 1 })}
                    </SimpleText>
                    <StockOperations quantity={storageExtending} stock={24} className="mt-48 Mw-230" onUpdate={(quantity) => setStorageExtending(quantity)}/>
                    <p className="mt-48 fs-20 fs-14--992 fw-500 text-center">
                      {t("DigitalStorage.countBottle", {count: collection.quantity})} x {month_price} {t("General.mdl")}/{t("General.month")} x {t("DigitalStorage.countMonth", { count: storageExtending })} = {collection.quantity * month_price * storageExtending} {t("General.mdl")}
                    </p>
                    <Button theme={Theme.Blue} className="Mw-360 mt-48 mt-24--992" onClick={() => setStep(steps.Result)}>
                      {t("DigitalStorage.confirmPeriod")}
                    </Button>
                  </FlexContainer>
                );
              case steps.Result:
                return (
                  <FlexContainer>
                    <p className="mt-48 fs-20 mt-12--992 fw-500">
                      {t("DigitalStorage.yourOrder")}:
                    </p>
                    <p className="mt-12 fs-20 fs-16--992 fw-500">
                      {t("DigitalStorage.wineStorage")}: {t("DigitalStorage.countMonth", {count: storageExtending})} ({t("DigitalStorage.untilDate", {date: moment().add(storageExtending, 'months').format('DD.MM.YYYY')})})
                    </p>
                    <p className="mt-48 mt-36--992 fs-32">
                      {t("DigitalStorage.total")}: <span className="fw-700">{collection.quantity * month_price * storageExtending} {t("General.mdl")}</span>
                    </p>
                    <FlexContainer className="Mw-560 mt-48 mt-36--992" direction={Direction.Row} gap={12}>
                      <Button className={styles.outlineButton} appearance={ButtonAppearance.Bordered} onClick={() => setStep(steps.Quantity)}>
                        {t("General.back")}
                      </Button>
                      <Button theme={Theme.Blue} onClick={callbackOnFinish}>
                        {t("DigitalStorage.addToCart")}
                      </Button>
                    </FlexContainer>
                  </FlexContainer>
                );
            }})()}
        </CSSTransition>
      </SwitchTransition>
    </FlexContainer>
  );
}

const OrderDeliveryModal = ({ collection, wine, callbackOnFinish }: TOrderDeliveryModal) => {
  const [t] = useTranslation(I18Namespace.Main);
  const authUser = useContext(AuthUserContext);
  const modal = useContext(ModalContext);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState(authUser.profile?.first_name || "");
  const [lastName, setLastName] = useState(authUser.profile?.last_name || "");
  const [quantity, setQuantity] = useState(collection.quantity);

  const submitForm = () => {
    StorageApiFactory().orderCollectionDelivery(collection.id, {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      quantity: quantity,
    }).then((response) => {

      callbackOnFinish();

      modal.showModal(
        <SuccessModalContent message={t("DigitalStorage.orderDeliverySuccess")} onClick={modal.closeModal}/>,
        {
          className: "Mw-560",
          theme: Theme.Gray,
          onBackdropClick: modal.closeModal,
        });

    }).catch((error) => {
      if (error instanceof AxiosError) {
        const data = error.response?.data;
        if (data && data.hasOwnProperty("error")) {
          modal.showModal(
            <ErrorModalContent message={t(`ValidationError.${data.error}`)}
                               callbackOnClose={modal.closeModal}/>,
            {
              className: "Mw-560",
              theme: Theme.Gray,
              onBackdropClick: modal.closeModal,
            });
        }
      }
    });
  };

  return (
    <FlexContainer className={styles.orderDeliveryModal}>
      <DeliveryIcon className={styles.modalIcon} />
      <Heading2 className="mt-12 mt-0--992 fs-26-36--992">{t("DigitalStorage.orderDelivery")}</Heading2>
      <SimpleText className="mt-24 mt-12--992">{t("General.for")}</SimpleText>
      <SimpleText className="text-center">{wine.name} {wine.year}</SimpleText>
      <StockOperations quantity={collection.quantity} stock={collection.quantity} onUpdate={(q) => setQuantity(q)} className="mt-48 mt-24--992 Mw-230"/>
      <p className="mt-12 fs-20 fw-500">
        {t("General.bottles")}
      </p>

      <FlexContainer className="Mw-560 mt-24 mt-12--992">
        <FlexContainer className={styles.containerName}>
          <Textbox type="text" placeholder={t("Form.firstName") as string} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
          <Textbox type="text" placeholder={t("Form.lastName") as string} value={lastName} onChange={(e) => setLastName(e.target.value)}/>
        </FlexContainer>
        <Textbox type="text" className="mt-12" placeholder={t("Form.yourEmail") as string} value={email} onChange={(e) => setEmail(e.target.value)}/>
        <Textbox type="text" className="mt-12" value={phone} onChange={(e) => setPhone(e.target.value)}
                 placeholder={t("Form.yourPhoneNumber") as string}/>
        <SimpleText className="mt-12 text-center">
          {t("DigitalStorage.orderDescription")}
        </SimpleText>
      </FlexContainer>

      <Button theme={Theme.Blue} disabled={email === "" || phone === "" || firstName === "" || lastName === ""} className="Mw-360 mt-48 mt-24--992" onClick={submitForm}>
        {t("DigitalStorage.orderDelivery")}
      </Button>
    </FlexContainer>
  );
}

type TProps = {
  wine: WineInCollection;
}

const WineCellarWines = ({ wine }: TProps) => {

  const limit = 10;
  const expireDays = 15;
  const { wineId } = useParams();
  const modal = useContext(ModalContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [t] = useTranslation(I18Namespace.Main);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1);
  const offset = (currentPage - 1) * limit;
  const [collection, setCollection] = useState<CollectionList>(wine.collections);

  const viewExtendingPeriod = (collection: Collection, wine: WineInCollection) => {
    modal.showModal(
      <StorageExtendModal collection={collection} wine={wine} callbackOnFinish={modal.closeModal}/>,
      {
        className: "Mw-760",
        onBackdropClick: modal.closeModal,
      });
  };

  const viewOrderDelivery = (collection: Collection, wine: WineInCollection) => {
    modal.showModal(
      <OrderDeliveryModal collection={collection} wine={wine} callbackOnFinish={modal.closeModal}/>,
      {
        className: "Mw-760",
        theme: Theme.Dark,
        onBackdropClick: modal.closeModal,
      });
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    if (wineId && wine) {
      StorageApiFactory().listCollectionsByWine(parseInt(wineId), offset, limit).then((response) => {
        setCollection(response.data);
      }).catch((error) => {

      });
    }
  }, [wine, wineId, offset]);

  useEffect(() => {
    updateUrlParams({page: currentPage > 1 ? currentPage : undefined});
  }, [currentPage]);

  return (
    <>
      <FlexContainer horizontalAlign={HorizontalAlign.Left}
                     className={`centered-content mt-36 ${styles.scrollableTableContainer}`}>
        <table className="table-cellar">
          <thead>
            <tr>
              <th className="thead-cell text-nowrap">{t("TableHead.purchaseDate")}</th>
              <th className="thead-cell text-nowrap">{t("TableHead.dateOfExpiration")}</th>
              <th className="thead-cell text-nowrap">{t("TableHead.storage")}</th>
              <th className="thead-cell h-center text-nowrap">{t("TableHead.quantityOfBottles")}</th>
              <th className="thead-cell text-nowrap">{t("TableHead.pricePerBottle")}</th>
              <th className="thead-cell text-nowrap">{t("TableHead.collectionCost")}</th>
              <th className="thead-cell text-nowrap h-center">{t("TableHead.cellarServices")}</th>
            </tr>
          </thead>
          <tbody>
          {collection && collection.items.map((item) => (
            <tr key={item.id} className="tbody-row">
              <td className="tbody-cell v-center">{moment(item.purchase_date).format("DD.MM.YYYY")}</td>
              <td className={`tbody-cell v-center ${moment(item.expiration_date).diff(moment(), 'days') <= expireDays ? styles.dateExpire : ""}`}>
                <FlexContainer direction={Direction.Row} horizontalAlign={HorizontalAlign.Left} gap={12}>
                  {moment(item.expiration_date).format("DD.MM.YYYY")} {moment(item.expiration_date).diff(moment(), 'days') <= expireDays ? <RenewalIcon className={styles.renewalButton} onClick={() => viewExtendingPeriod(item, wine)}/> : ""}
                </FlexContainer>
              </td>
              <td className="tbody-cell v-center">{item.storage}</td>
              <td className="tbody-cell v-center h-center">{item.quantity}</td>
              <td className="tbody-cell v-center">{item.price} {t("General.mdl")}</td>
              <td className="tbody-cell v-center">{parseFloat((item.price * item.quantity).toFixed(2))} {t("General.mdl")}</td>
              <td className="tbody-cell v-center text-nowrap">
                <FlexContainer direction={Direction.Row} gap={12}>
                  <Button theme={Theme.Blue} disabled={moment(item.expiration_date).diff(moment(), 'days') <= expireDays} onClick={() => navigate(`/digital-storage/wine-cellar/${wineId}/conditions/${item.id}`)}>
                    {t("DigitalStorage.conditions")}
                  </Button>
                  <Button icon={<DeliveryIcon />} iconPosition={ButtonIconPosition.LeftCenter} theme={Theme.Blue} onClick={() => viewOrderDelivery(item, wine)}>
                    {t("DigitalStorage.orderDelivery")}
                  </Button>
                </FlexContainer>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </FlexContainer>
      <FlexContainer className="pagination mt-12" direction={Direction.Row} gap={40}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={wine?.collections.total || 0}
          pageSize={limit}
          onPageChange={onPageChange}
        />
      </FlexContainer>
    </>
  )
}

export default WineCellarWines;