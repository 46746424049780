import { createContext } from "react";

export type TProfile = {
    first_name: string,
    last_name: string
}

type Props = {
    profile: TProfile | null,
    setProfile: (profile: TProfile | null) => void
};

export const initialState: Props = {
    profile: null,
    setProfile: () => { }
};
export default createContext(initialState);