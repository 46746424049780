import { AxiosError } from "axios";
import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { ErrorType, EventCart, MarketApiFactory } from "../../services/api/v1";
import Context, { initialState } from "./Context";

type Props = PropsWithChildren<unknown>;

export default function Provider({ children }: Props) {
  const [data, setData] = useState<EventCart | null>(initialState.data);
  const [isEmpty, setIsEmpty] = useState(initialState.isEmpty);
  const [eventId, setEventId] = useState<string | null>(initialState.eventId);
  const [wineCellarId, setWineCellarId] = useState<string | null>(initialState.wineCellarId);

  const [isAllowed, setIsAllowed] = useState(true);
  const [loading, setLoading] = useState(true);

  const matchTasting = useMatch("/tasting/:tastingId/*");
  const tastingId = matchTasting?.params.tastingId;

  const matchDigitalStorage = useMatch("/digital-storage/wine-cellar/:wineId/*");
  const wineId = matchDigitalStorage?.params.wineId;

  const matchTastingCart = useMatch("/tasting/:tastingId/cart");

  const loadEventData = useCallback((tastingId: string, offset: number) => {
    MarketApiFactory().getEventCart(parseInt(tastingId), offset).then(res => {

      setData((result) => {
        if (result === null || offset === 0) {
          return res.data;
        }

        return {
          ...result,
          items: [...result.items, ...res.data.items]
        };
      });

      setIsEmpty(!(res.data.total > 0));
      setEventId(tastingId);
    }).catch(error => {
      setIsEmpty(true);
      if (error instanceof AxiosError) {
        const data = error.response?.data;
        if (data && data.hasOwnProperty("error")) {
          if (data.error === ErrorType.EventVendorNotActive) {
            setIsAllowed(false);
          }
        }
      }
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setEventId(null);
    if (tastingId) {
      loadEventData(tastingId, 0);
    }
  }, [tastingId, loadEventData]);

  const loadNextPage = () => {
    if (tastingId && data) {
      loadEventData(tastingId, data.items.length);
    }
  };

  const loadWineCellarData = (wineId: string) => {
    setWineCellarId(wineId);
  };

  const refreshCart = () => {
    if (tastingId) {
      loadEventData(tastingId, 0);
    }
  };

  useEffect(() => {
    setWineCellarId(null);
    if (wineId) {
      // load data
      loadWineCellarData(wineId);
    }
  }, [wineId]);

  return (
    <Context.Provider value={{
      isEmpty: isEmpty,
      eventId: eventId,
      data: data,
      refresh: refreshCart,
      loadItems: loadNextPage,
      wineCellarId: wineCellarId,
    }}>

      {(() => {
        // Check current page is Tasting Cart
        // wait for loading content
        // and check if user is allowed to access.
        if (matchTastingCart) {
          if (!loading) {
            if (!isAllowed) {
              return <Navigate to={`/tasting/${tastingId}/results`}/>;
            } else {
              return children;
            }
          }
        } else {
          return children;
        }
      })()}

    </Context.Provider>
  );
}

