import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from "react";
import { Theme } from "./index";
import styles from "./Button.module.scss";

export enum ButtonIconPosition {
  Left = "left",
  Right = "right",
  LeftCenter = "leftCenter",
  RightCenter = "rightCenter",
}

export enum ButtonAppearance {
  Bordered = "bordered",
  Filled = "filled"
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: React.JSX.Element,
  iconPosition?: ButtonIconPosition,
  appearance?: ButtonAppearance,
  theme?: Theme,
};

export type ButtonRef = ForwardedRef<HTMLButtonElement>;

const Button = ({ children, className, icon, iconPosition, appearance, theme, ...props }: ButtonProps, ref: ButtonRef) => {

  return (
    <>
      <button
        ref={ref}
        className={`${styles.button} ${styles[iconPosition ?? ButtonIconPosition.RightCenter]} ${styles[appearance ?? ButtonAppearance.Filled]} ${styles[theme ?? Theme.DarkBlue]} ${className ?? ""}`} {...props}>
        {icon !== undefined && (
          <span className={`${styles.icon}`}>{icon}</span>
        )}
        {children}
      </button>
    </>
  );
};

export default forwardRef(Button);