// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Heading1_heading1__XThFQ{font-family:var(--font-main);font-weight:700;font-size:48px;line-height:48px;color:var(--heading-1-color-default)}.Heading1_heading1__XThFQ.Heading1_blue__2SckP{--heading-1-color-default: #242528}", "",{"version":3,"sources":["webpack://./src/components/Heading1.module.scss"],"names":[],"mappings":"AAEA,0BACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oCAAA,CAEA,+CACE,kCAAA","sourcesContent":["@use \"../styles/colors\";\n\n.heading1 {\n  font-family: var(--font-main);\n  font-weight: 700;\n  font-size: 48px;\n  line-height: 48px;\n  color: var(--heading-1-color-default);\n\n  &.blue {\n    --heading-1-color-default: #{colors.$dark-gray};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading1": "Heading1_heading1__XThFQ",
	"blue": "Heading1_blue__2SckP"
};
export default ___CSS_LOADER_EXPORT___;
