import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { Theme } from "./index";
import styles from "./Textbox.module.scss";

export enum TextboxIconPosition {
  Left = "left",
  Right = "right",
}

export enum TextAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

export enum TextboxAppearance {
  Bordered = "bordered",
  Filled = "filled",
}

export type TextboxRef = ForwardedRef<HTMLInputElement>;

export type TextboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
  textAlign?: TextAlign,
  theme?: Theme,
  type?: "text" | "tel" | "email" | "number" | "password",
  icon?: React.JSX.Element,
  iconPosition?: TextboxIconPosition,
  iconClickAction?: () => void,
  disabled?: boolean,
  appearance?: TextboxAppearance,
};

const Textbox = ({ icon, iconPosition, iconClickAction, className, textAlign, theme, type, disabled, appearance, ...props }: TextboxProps, ref: TextboxRef) => {
  return (
    <div className={`${styles.textboxWrapper} ${disabled ? styles["disabled"] :""} ${className ?? ""}`}>
      <input ref={ref} type={type} className={`${styles.textbox} ${styles[theme ?? Theme.Gray]} ${styles[textAlign ?? TextAlign.Left]} ${icon ? styles["icon-" + (iconPosition ?? TextboxIconPosition.Right)]:""} ${styles[appearance ?? TextboxAppearance.Bordered]}`} disabled={disabled ?? false} {...props} />
      {icon !== undefined && (
        <span className={`${styles.icon} ${styles[iconPosition ?? TextboxIconPosition.Right]}`} onClick={iconClickAction ?? undefined}>{icon}</span>
      )}
    </div>
  );
};

export default forwardRef(Textbox);