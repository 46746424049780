import { Direction, FlexContainer, Heading3, HorizontalAlign, VerticalAlign } from "@rizov/rizov-ui-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TaxonomyContext } from "../context/taxonomy";
import { I18Namespace } from "../i18n";
import styles from "../pages/WineCellar.module.scss";
import { WineInCollection } from "../services/api/v1";
import { getWineImage } from "../utils/helpers";

type TProps = {
  wine: WineInCollection
}
const WineCellarWine = ({ wine }: TProps) => {
  const [t] = useTranslation(I18Namespace.Main);
  const taxonomy = useContext(TaxonomyContext);

  return (
    <>
      <div className={styles.imageContainer}>
        <img src={wine.photo ?? getWineImage(wine.category ?? [])} alt="wine" className={styles.image} />
      </div>
      <Heading3 className={`${styles.title} fs-30-32--992`}>
        {wine.name} {wine.year}
      </Heading3>
      <FlexContainer className={styles.composition} direction={Direction.Row} horizontalAlign={HorizontalAlign.Left} verticalAlign={VerticalAlign.Top}>
        <ul className={styles.list}>
          <li className={styles.item}>
            <span>{t("General.winery")}:</span> {wine.vineyard}
          </li>
          <li className={styles.item}>
            <span>{t("General.country")}:</span> {taxonomy.translate(wine.country)}
          </li>
          <li className={styles.item}>
            <span>{t("General.region")}:</span> {taxonomy.translate(wine.region)}
          </li>
          <li className={styles.item}>
            <span>{t("General.type")}:</span> {taxonomy.translate(wine.category)}
          </li>
        </ul>

        <ul className={styles.list}>
          <li className={styles.item}>
            <span>{t("General.alcohol")}:</span> {wine.alcohol}%
          </li>
          <li className={styles.item}>
            <span>{t("General.sugar")}:</span> {wine.sugar} g/l
          </li>
          {wine.grapes && wine.grapes.length > 0 && (
            <li className={styles.item}>
              <span>{t("General.grapes")}:</span>&nbsp;
               {wine.grapes.map(grape => (`${grape.percent}% ${grape.name}`)).join(", ")}
            </li>
          )}
        </ul>
      </FlexContainer>
    </>
  )
}

export default WineCellarWine;
