import { PropsWithChildren, ReactNode, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Modal from "../../components/Modal";
import Context, { TModalParams } from "./Context";

type Props = PropsWithChildren;

export default function Provider({ children }: Props) {
  const [modals, setModals] = useState<{ content: ReactNode, params?: TModalParams }[]>([]);

  return (
    <Context.Provider value={{
      showModal: (content, params) => {
        setModals((modals) => modals.concat([{ content, params }]));
      },
      closeModal: () => {
        setModals((modals) => modals.slice(0, -1));
      },
    }}>
      {children}

      <TransitionGroup>
        {modals.map((modal, key) => (
          <CSSTransition
            key={key}
            timeout={200}
            classNames="modal-fade"
          >
            <Modal {...modal.params}>
              {modal.content}
            </Modal>
          </CSSTransition>
        ))}
      </TransitionGroup>

    </Context.Provider>
  );
}
