import { ModalProvider } from "@rizov/rizov-ui-react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Preloader from "./components/Preloader";
import { CartProvider } from "./context/cart";
import { TaxonomyProvider } from "./context/taxonomy";
import reportWebVitals from "./reportWebVitals";
import { AuthUserProvider } from "./context/authUser";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<Preloader/>}>
      <AuthUserProvider>
        <TaxonomyProvider>
          <BrowserRouter>
            <ModalProvider>
              <CartProvider>
                <App/>
              </CartProvider>
            </ModalProvider>
          </BrowserRouter>
        </TaxonomyProvider>
      </AuthUserProvider>
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
