import { Button, Direction, GridContainer, TextAlign, Textbox, TextboxAppearance, Theme } from "@rizov/rizov-ui-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as MinusIcon } from "../assets/images/minus.svg";
import { ReactComponent as PlusIcon } from "../assets/images/plus.svg";
import { clamp } from "../utils/helpers";
import styles from "./StockOperations.module.scss";

type TStockOperationsProps = {
  quantity?: number;
  stock: number;
  className?: string,
  onUpdate?: (quantity: number) => void;
}

const StockOperations = ({ quantity, stock, className, onUpdate }: TStockOperationsProps) => {
  const [customQuantity, setCustomQuantity] = useState<number>(stock > 1 ? 1 : 0);

  const updateQuantity = (quantity: number) => {
    if (typeof onUpdate === "function") {
      onUpdate(quantity);
    }
    setCustomQuantity(quantity);
  }

  useEffect(() => {
    if (typeof quantity !== "undefined") {
      setCustomQuantity(quantity);
    }
  }, [quantity]);

  return (
    <GridContainer direction={Direction.Row} gap={10} className={`${styles.stockOptions} ${className ?? ""}`}>
      <Button theme={Theme.Gray} disabled={customQuantity === 1}  className="height-full" onClick={() => {
        updateQuantity(stock >= customQuantity && customQuantity > 1 ? customQuantity - 1 : customQuantity);
      }}>
        <MinusIcon/>
      </Button>
      <Textbox type="tel" min={1} max={stock} className={styles.quantity} value={customQuantity}
               textAlign={TextAlign.Center}
               appearance={TextboxAppearance.Filled}
               onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                 const value = parseInt(e.target.value);
                 if (!isNaN(value)) {
                   updateQuantity(clamp(value, 1, stock));
                 }
               }}/>
      <Button theme={Theme.Gray} disabled={customQuantity === stock} className="height-full" onClick={() => {
        updateQuantity(stock > customQuantity ? customQuantity + 1 : customQuantity);
      }}>
        <PlusIcon/>
      </Button>
    </GridContainer>
  );
};

export default StockOperations;