import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Theme } from "./index";
import TextAlignWrapper, { TextAlign } from "./TextAlignWrapper";
import styles from "./Heading2.module.scss";

export type Heading2Props = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  textAlign?: TextAlign,
  theme?: Theme,
};

const Heading2: FunctionComponent<Heading2Props> = ({ className, children, textAlign, theme, ...props }: Heading2Props) => {
  return (
    <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
      <h2 className={`${styles.heading2} ${styles[theme ?? Theme.Dark]} ${className ?? ""}`} {...props}>
        {children}
      </h2>
    </TextAlignWrapper>
  );
};

export default Heading2;
