import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import styles from "./GridContainer.module.scss";
import { Direction, HorizontalAlign, VerticalAlign } from "./index";

export type GridContainerProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  direction?: Direction,
  verticalAlign?: VerticalAlign,
  horizontalAlign?: HorizontalAlign,
  gap?: number | string,
  rows?: number,
  columns?: number,
};

const GridContainer: FunctionComponent<GridContainerProps> = ({ className, children, direction, horizontalAlign, verticalAlign, gap, rows, columns, style, ...props }: GridContainerProps) => {
  if (process.env.NODE_ENV === "development") {
    if (direction === Direction.Column && rows === undefined) {
      console.warn("The \"direction\" property is set to \"column\", so the \"rows\" one should be set too.");
    }
  }

  return (
    <div
      className={`${styles.gridContainer} ${styles[direction ?? Direction.Row]} ${styles[horizontalAlign ?? HorizontalAlign.Center]} ${styles[verticalAlign ?? VerticalAlign.Middle]} ${className ?? ""}`}
      style={(gap || rows || columns) ?
        Object.assign({
          gap: gap,
          gridTemplateColumns: columns ? `repeat(${columns}, auto)` : undefined,
          gridTemplateRows: rows ? `repeat(${rows}, auto)` : undefined,
        }, style) :
        style
      }
      {...props}
    >
      {children}
    </div>
  );
};

export default GridContainer;
