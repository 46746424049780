import { createContext } from "react";

const emptyFunction = () => {
  return;
};

export type TTaxonomyProps = {
  id: number,
  name: string,
}

type Props = {
  taxonomy: TTaxonomyProps[],
  setTaxonomy: (item: TTaxonomyProps) => void,
  translate: (tid: number|number[]) => string,
}

export const initialState: Props = {
  taxonomy: [],
  setTaxonomy: emptyFunction,
  translate: () => "",
};

export default createContext<Props>(initialState);
