import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { updateUrlParams } from "../utils/helpers";

type StateWithSearchParamsResponse<T> = [
    T | undefined,
  Dispatch<SetStateAction<T | undefined>>,
];

export function useStateWithSearchParams<T>(
  searchParamName: string,
  defaultValue?: T,
): StateWithSearchParamsResponse<T> {
  const [searchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const [value, setValue] = useState<T | undefined>(searchParamsState as T);

  useEffect(() => {
    updateUrlParams({ [searchParamName]: value });
  }, [searchParamName, value]);

  return [value, setValue];
}