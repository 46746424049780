import React, { ComponentProps, createElement, JSXElementConstructor, PropsWithChildren, useEffect, useRef, useState } from "react";
import styles from "./Dropdown.module.scss";

type TDropdownProps = PropsWithChildren<{
  title: string | React.JSX.Element;
  className?: string;
}>;

const Dropdown = ({ title, className, children }: TDropdownProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`${styles.dropdown} ${className ?? ""} ${isOpen ? styles.open : ""}`} ref={dropdownRef}>
      <div className={`${styles.dropdownToggle}`} onClick={handleDropdown}>{title}</div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {React.Children.map(children as Array<React.JSX.Element>, function (oldChild) {
            return Object.assign({}, oldChild, {
              props: Object.assign({}, oldChild.props, {
                onClick: (e: React.MouseEvent) => {
                  if (oldChild.props.onClick !== undefined) {
                    oldChild.props.onClick(e);
                  }
                  handleDropdown();
                },
              }),
            });
          })}
        </div>
      )}
    </div>
  );
};

export type DropdownItemProps<T extends keyof React.JSX.IntrinsicElements | JSXElementConstructor<any>> = PropsWithChildren<{
  element: T;
} & ComponentProps<T>>;

export function DropdownItem<T extends keyof React.JSX.IntrinsicElements | JSXElementConstructor<any>>({
  element,
  ...props
}: DropdownItemProps<T>) {

  const { className, ...otherProps } = props;
  return createElement(element, {
    className: `${styles.dropdownLink} ${className ?? ""}`,
    ...otherProps,
  });
}

export default Dropdown;