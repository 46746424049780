import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import styles from "./Checkbox.module.scss";

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "children">;

export type CheckboxRef = ForwardedRef<HTMLInputElement>;

const Checkbox = ({ className, ...props }: CheckboxProps, ref: CheckboxRef) => {
  return (
    <input
      ref={ref}
      type="checkbox"
      className={`${styles.checkbox} ${className ?? ""}`}
      {...props}
    />
  );
};

export default forwardRef(Checkbox);
